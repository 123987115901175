import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { node, func } from 'prop-types'

import ModalBackground from './modal.style'
import IconButton from '../../elements/buttons/IconButton'

const Modal = ({ children, closeModalHandler }) => {
  const classNameID = 'm-modal'
  const [modalRoot, setModalRoot] = useState(null)

  useEffect(() => {
    document.querySelector('body').classList.add('body-hidden')

    const modal = document.getElementById('modal-root')
    setModalRoot(modal)

    return () => {
      document.querySelector('body').classList.remove('body-hidden')
    }
  }, [])

  return modalRoot
    ? ReactDOM.createPortal(
      <ModalBackground className={classNameID}>
        {closeModalHandler ? (
          <IconButton onClick={closeModalHandler} className={`${classNameID}__overlay-btn`}>
            <div className={`${classNameID}__overlay`} />
          </IconButton>
        ) : (
          <div className={`${classNameID}__overlay`} />
        )}
        <div className={`${classNameID}__content`}>{children}</div>
      </ModalBackground>,
      modalRoot
      )
    : null
}

Modal.propTypes = {
  children: node,
  closeModalHandler: func,
}

Modal.defaultProps = {
  children: null,
  closeModalHandler: null,
}

export default Modal
