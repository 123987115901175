import styled from 'styled-components'

import { device } from '../../../theme/mediaQueries'

const ModuleStyled = styled.div`
  cursor: pointer;
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: 1fr 20px;
  border-radius: 30px 0px;
  border: 2px solid rgb(229, 229, 229);
  padding: 20px;
  position: relative;

  .m-accordion-summary__arrow {
    display: flex;
    justify-content: center;
    top: 4px;
    position: relative;
  }
  &.is-expanded {
    background-color: ${props => props.theme.colors.blue};
    border: 2px solid transparent;

    .m-accordion-summary__arrow {
      top: 14px;
      transform: rotate(180deg);
      transform-origin: top center;
    }
  }

  &.is-Menu{
    background-color: transparent;
    color: ${props => props.theme.colors.blue};
    border-radius: 0;
    border-left: none;
    border-right: none;
    border-top: none;
    padding-left: 0;
    border-bottom: 2px solid #E5F1FD;
    .m-accordion-summary__arrow {
      top: 10px;
      svg path{
        fill: ${props => props.theme.colors.pink};
      }
    } 

    &.is-expanded{
     
      border-top: none;
      .m-accordion-summary__arrow {
        top: 20px;
      }
    }
  }

  @media ${device.sm} {
    border-radius: 20px 0px;
    grid-column-gap: 12px;
    padding: 17px 20px 17px 30px;

    .m-accordion-summary__arrow {
      top: 8px;
    }
    &.is-expanded {
      .m-accordion-summary__arrow {
        top: 16px;
      }
    }
  }

  @media ${device.md} {
    padding: 17px 17px 17px 30px;
  }

  @media ${device.lg} {
    padding: 17px 30px 17px 30px;
  }

  @media ${device.xl} {
    
  }
`
export default ModuleStyled
