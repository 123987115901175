import * as React from 'react'
import { string, number, oneOfType } from 'prop-types'

const SvgLogoLight = ({ width, height, className }) => (
  <svg width={width} height={height} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 530 230" xmlSpace="preserve">
    <path fill="#0F93ED" d="M250.3 141.4h-8.6c-.6 0-1.1-.2-1.3-.9l-3.2-9.3h-20.1l-3.2 9.3c-.2.7-.7.9-1.3.9H204c-.8 0-1.2-.5-.9-1.3L222 88.4c.2-.6.7-.9 1.3-.9h7.7c.6 0 1.2.3 1.5.9l18.7 51.7c.3.8-.1 1.3-.9 1.3zm-16-19.2-7.1-21.1-7.1 21.1h14.2z" />
    <g fill="#0F93ED">
      <path d="M290.7 104.8V141c0 12-11.2 15.7-19.8 15.7-6.5 0-12.3-1.9-16.3-4.6-.5-.4-.6-.9-.3-1.5l2.5-4.6c.4-.6.8-.8 1.5-.5 3.5 1.9 7 2.9 11.6 2.9s11.3-1.8 11.3-8v-4.2c-2.8 3.2-6.6 5.6-11.7 5.6-10.6 0-18.3-8.8-18.3-19.4s7.7-19.4 18.3-19.4c4.8 0 8.7 2.2 11.5 5.4l.2-3.4c.1-.7.5-1.2 1.2-1.2h7.1c.8-.1 1.2.3 1.2 1zm-10.2 17.4c0-5.3-4.1-10.1-9.6-10.1-5.6 0-9.7 4.5-9.7 10.1 0 5.7 4.1 10.3 9.7 10.3 5.5-.1 9.6-5 9.6-10.3zM333.1 104.8v35.5c0 .7-.5 1.2-1.2 1.2h-7.6c-.7 0-1.1-.5-1.2-1.2l-.3-4.2c-2.6 3.7-6.5 6.4-11.3 6.4-8.5 0-14.3-5.9-14.3-16v-21.7c0-.7.5-1.2 1.2-1.2h7.9c.7 0 1.2.5 1.2 1.2v19.8c0 6.2 3.6 8.4 7.2 8.4 5.5 0 8.2-4.6 8.2-7.3v-20.9c0-.7.5-1.2 1.2-1.2h7.9c.6.1 1.1.5 1.1 1.2zM378.3 104.8v35.5c0 .7-.5 1.2-1.2 1.2H370c-.7 0-1.1-.5-1.2-1.2l-.3-3.2c-2.7 3.2-6.5 5.4-11.4 5.4-10.6 0-18.3-8.8-18.3-19.8s7.7-19.8 18.3-19.8c4.8 0 8.6 2.3 11.5 5.6l.2-3.5c.1-.7.5-1.2 1.2-1.2h7.1c.7-.1 1.2.3 1.2 1zM368 122.6c0-5.7-4.1-10.5-9.6-10.5-5.6 0-9.7 4.5-9.7 10.5 0 6.1 4.1 10.6 9.7 10.6 5.5 0 9.6-4.9 9.6-10.6zM412.8 130.9c0 7.3-7.5 11.6-14.4 11.6-6.5 0-11.7-3.2-14.4-6.3-.5-.5-.3-1.1 0-1.5l3-4.2c.5-.6 1-.5 1.7.1 3.1 2 6.4 3.5 9.4 3.5 2.5 0 4.9-.8 4.9-3.2 0-6-18.3-3.7-18.3-16.4 0-7.2 6.3-11.5 13.1-11.5 4.9 0 9.8 2 13 5.7.5.5.3 1.2-.1 1.6l-2.9 3.9c-.5.6-1 .5-1.7-.1-2.9-1.9-4.7-2.9-7.4-2.9-2.4 0-4.2 1.3-4.2 3.2 0 6 18.3 2.6 18.3 16.5z" />
    </g>
    <g fill="#0F93ED">
      <path d="M243.3 163.4v54c0 .7-.5 1.2-1.2 1.2H235c-.7 0-1.1-.5-1.2-1.2l-.3-3.2c-2.7 3.2-6.5 5.4-11.4 5.4-10.6 0-18.3-8.8-18.3-19.8s7.7-19.8 18.3-19.8c4.5 0 8.2 2 11 4.9v-21.4c0-.7.5-1.2 1.2-1.2h7.9c.6 0 1.1.4 1.1 1.1zM233 199.7c0-5.7-4.1-10.5-9.6-10.5-5.6 0-9.7 4.5-9.7 10.5 0 6.1 4.1 10.6 9.7 10.6 5.5 0 9.6-4.9 9.6-10.6zM283.4 203.4h-24.2c1 5 4.9 7.9 10.8 7.9 3 0 5.4-.8 8-2.6.7-.5 1.2-.5 1.7.1l3.4 3.3c.5.5.5 1.1-.1 1.7-3.6 3.6-8.6 5.8-14 5.8-11.8 0-19.9-8.7-19.9-19.8s8.1-19.8 19-19.8c11 0 18 8.7 18 19 0 2.9-.4 4.4-2.7 4.4zm-24.4-6.6h18c-.7-5.3-4.3-8.3-8.9-8.3-4.6.1-8.3 3-9.1 8.3z" />
    </g>
    <g fill="#0F93ED">
      <path d="M336.5 203.6c0 10.2-9.3 16-18.7 16-7.9 0-14.7-4.2-18.2-8.4-.4-.5-.4-1.1.1-1.6l4.6-5c.5-.5 1.1-.6 1.7-.1 3.7 3.2 7.3 5.2 12 5.2 4.6 0 8.2-2.5 8.2-6.3 0-9.6-25.6-6.6-25.6-24.3 0-9.4 7.9-15.1 16.8-15.1 6.7 0 12.2 2.9 16.5 7.2.5.5.5 1 0 1.6l-4.2 5.2c-.5.6-1 .7-1.6.2-3.8-2.9-6.5-4.3-10.3-4.3-4.3 0-6.9 2.3-6.9 5.5-.1 9.4 25.6 5.5 25.6 24.2zM380.5 181.9v35.5c0 .7-.5 1.2-1.2 1.2h-7.1c-.7 0-1.1-.5-1.2-1.2l-.3-3.2c-2.7 3.2-6.5 5.4-11.4 5.4-10.6 0-18.3-8.8-18.3-19.8s7.7-19.8 18.3-19.8c4.8 0 8.6 2.3 11.5 5.6l.2-3.5c.1-.7.5-1.2 1.2-1.2h7.1c.7-.1 1.2.3 1.2 1zm-10.3 17.8c0-5.7-4.1-10.5-9.6-10.5-5.6 0-9.7 4.5-9.7 10.5 0 6.1 4.1 10.6 9.7 10.6 5.5 0 9.6-4.9 9.6-10.6zM388.6 162.3h7.9c.7 0 1.2.5 1.2 1.2v54c0 .7-.5 1.2-1.2 1.2h-7.9c-.7 0-1.2-.5-1.2-1.2v-54c0-.8.5-1.2 1.2-1.2zM426.4 181.9v6c0 .7-.5 1.2-1.2 1.2h-5.4v28.3c0 .7-.5 1.2-1.2 1.2h-7.9c-.7 0-1.2-.5-1.2-1.2v-28.3h-5.4c-.7 0-1.2-.5-1.2-1.2v-6c0-.7.5-1.2 1.2-1.2h5.4v-10.4c0-.7.5-1.2 1.2-1.2h7.9c.7 0 1.2.5 1.2 1.2v10.4h5.4c.7.1 1.2.5 1.2 1.2z" />
    </g>
    <g fill="#0F93ED">
      <path d="M431.4 168c0-3.1 2.5-5.6 5.6-5.6s5.6 2.5 5.6 5.6-2.5 5.6-5.6 5.6-5.6-2.5-5.6-5.6zm1.7 12.8h7.9c.7 0 1.2.5 1.2 1.2v35.5c0 .7-.5 1.2-1.2 1.2h-7.9c-.7 0-1.2-.5-1.2-1.2V182c0-.8.5-1.2 1.2-1.2zM451 162.3h7.9c.7 0 1.2.5 1.2 1.2v54c0 .7-.5 1.2-1.2 1.2H451c-.7 0-1.2-.5-1.2-1.2v-54c.1-.8.6-1.2 1.2-1.2z" />
    </g>
    <g fill="#0F93ED">
      <path d="M469.1 162.3h7.9c.7 0 1.2.5 1.2 1.2v54c0 .7-.5 1.2-1.2 1.2h-7.9c-.7 0-1.2-.5-1.2-1.2v-54c0-.8.5-1.2 1.2-1.2zM484 199.7c0-11.1 8.7-19.8 19.6-19.8s19.6 8.7 19.6 19.8-8.7 19.8-19.6 19.8c-10.9.1-19.6-8.6-19.6-19.8zm29.4 0c0-6.1-4.4-10.4-9.8-10.4-5.5 0-9.8 4.3-9.8 10.4 0 6.1 4.3 10.4 9.8 10.4 5.4.1 9.8-4.3 9.8-10.4z" />
    </g>
    <path fill="#56C271" d="M4.6 229.4 81 152.9H19.2c-8.1 0-14.6 6.5-14.6 14.6v61.9z" />
    <path fill="#2CCCD3" d="M42.8 229.4 81 191.1H42.8z" />
    <path fill="#FB3898" d="M92 229.4c42.3 0 76.4-34.3 76.4-76.4h-38.1c-21.2 0-38.4 17.2-38.4 38.4v38h.1z" />
    <path fill="#FCCDBD" d="M130.4 152.8h38.1c0-42.3-34.3-76.4-76.4-76.4v38.1c-.1 21.2 17.2 38.3 38.3 38.3" />
    <path fill="#2795F2" d="M130.3 0H92v38.3c0 21.1 17.1 38.3 38.3 38.3s38.3-17.1 38.3-38.3S151.4 0 130.3 0m0 54.4c-8.9 0-16.1-7.2-16.1-16.1V22l16.1.1c8.9 0 16.1 7.2 16.1 16.1 0 8.9-7.2 16.2-16.1 16.2" />
    <path fill="#F4D660" d="M26.6 76.4C26.6 46.4 51 22 81 22V0C38.8 0 4.6 34.3 4.6 76.4s34.3 76.4 76.4 76.4v-22c-30 0-54.4-24.3-54.4-54.4" />
    <path fill="#ED6B00" d="M37.6 76.4c0 23.9 19.4 43.4 43.4 43.4V33.1c-23.9 0-43.4 19.4-43.4 43.3" />
  </svg>
)

SvgLogoLight.propTypes = {
  height: oneOfType([string, number]),
  width: oneOfType([string, number]),
  className: string,
}

SvgLogoLight.defaultProps = {
  height: '1em',
  width: '1em',
  className: '',
}

export default SvgLogoLight
