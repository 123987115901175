import React from "react"
import { Visible } from "react-awesome-styled-grid"
import { arrayOf, shape, string, func } from "prop-types"
import { isInternal } from "../../../helpers/url"

import Accordion from "../accordion/Accordion"
import AccordionSummary from "../accordion/AccordionSummary"
import AccordionHeader from "./accordionHeader"
import AccordionDetails from "../accordion/AccordionDetails"
import MenuButton from "../../elements/buttons/MenuButton"

import StyleMenuContainer from "./selectMenu.style"

const SelectMenu = ({ options, defaultValue, handleClose, panelName, onChange, expanded }) => {
  const classNameID = `m-select-menu`
  let count = 0

  const handleClick = () => {
    setTimeout(() => {
      handleClose()
    }, 500)
  }

  return (
    <StyleMenuContainer total={options.length}>
      <Visible xs sm>
        {options.length > 1 && (
          <Accordion className="is-Menu" onChange={() => onChange(panelName)} expanded={expanded === panelName}>
            <AccordionSummary className="is-Menu" expanded={expanded === panelName}>
              <AccordionHeader title={defaultValue} expanded={expanded === panelName} />
            </AccordionSummary>
            <AccordionDetails className={`${classNameID}__accordion-details`} expanded={expanded === panelName}>
              {options.map(item => {
                count += 1
                if (isInternal(item.url)) {
                  return (
                    <MenuButton key={`button__${count}`} className={`${classNameID}__button`} to={item.url} onClick={handleClick}>
                      {item.label}
                    </MenuButton>
                  )
                }
                return (
                  <MenuButton key={`button-url__${count}`} className={`${classNameID}__button`} href={item.url} target="_blank" onClick={handleClick}>
                    {item.label}
                  </MenuButton>
                )
              })}
            </AccordionDetails>
          </Accordion>
        )}
      </Visible>
    </StyleMenuContainer>
  )
}

SelectMenu.propTypes = {
  options: arrayOf(shape({})),
  defaultValue: string,
  handleClose: func,
  panelName: string,
  onChange: func,
  expanded: string,
}

SelectMenu.defaultProps = {
  options: [],
  defaultValue: "",
  handleClose: () => null,
  panelName: "panel0",
  onChange: () => null,
  expanded: ""
}

export default SelectMenu
