/* eslint-disable jsx-a11y/no-static-element-interactions */ /* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable consistent-return */ /* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { CSSTransition } from "react-transition-group"
import { Container, Row, Col, Visible } from "react-awesome-styled-grid"
import { func } from "prop-types"
import { isInternal } from "../../../helpers/url"

import ModuleStyled from "./menu.style"

import MenuButton from "../../elements/buttons/MenuButton"
import SelectMenu from "./selectMenu"
import SvgClose from "../../elements/svgs/SvgClose"
import SvgLogoLight from "../../elements/svgs/SvgLogoLight"
import SvgFacebook from "../../elements/svgs/social/SvgFacebook"
import SvgTwitter from "../../elements/svgs/social/SvgTwitter"
import SvgInstagram from "../../elements/svgs/social/SvgInstagram"
import SvgYoutube from "../../elements/svgs/social/SvgYoutube"
import IconLink from "../../elements/links/IconLink"

const HamburgerMenu = ({ handleClose }) => {
  const classNameID = `m-menu`
  const [itemsMenu, setItemsMenu] = useState(null)
  const [menuGeneral, setMenuGeneral] = useState(null)
  const [menuClients, setMenuClients] = useState(null)
  const [menuUs, setMenuUs] = useState(null)
  const [menuLegals, setMenuLegals] = useState(null)
  const [accordionItems, setAcordionItems] = useState([])
  const [expanded, setExpanded] = useState("panel0")

  const [optionsClients, setOptionsClients] = useState([])
  const [optionsUs, setOptionsUs] = useState([])

  let count = 0
  let countAccordion = 0
  let countItems = 0
  let countLegals = 0
  const [inProp, setInProp] = useState(false)

  const {
    allWpMenu,
    wpPost: {
      footer: { socialUrls },
    },
  } = useStaticQuery(graphql`
    query {
      allWpMenu {
        nodes {
          name
          menuItems {
            nodes {
              label
              childItems {
                nodes {
                  label
                  path
                }
              }
            }
          }
        }
      }
      wpPost(slug: { eq: "footer" }) {
        footer {
          socialUrls {
            icon
            url
          }
        }
      }
    }
  `)

  useEffect(() => {
    setInProp(true)
    setExpanded("panel0")
    const Menu = []

    const { nodes } = allWpMenu
    const items = nodes[0].menuItems

    items.nodes.map(item => {
      const { childItems } = item
      if (childItems.nodes.length > 0) {
        Menu.push(item)
      }
    })
    setItemsMenu(Menu)

    document.querySelector("body").classList.add("body-hidden")
    return () => {
      document.querySelector("body").classList.remove("body-hidden")
    }
  }, [])

  useEffect(() => {
    if (itemsMenu !== null) {
      itemsMenu.map(menu => {
        switch (menu.label) {
          case "General":
            return setMenuGeneral(menu)
          case "Clientes":
            return setMenuClients(menu)
          case "Nosotros":
            return setMenuUs(menu)
          case "Legales":
            return setMenuLegals(menu)
          default:
            break
        }
      })
    }
  }, [itemsMenu])

  useEffect(() => {
    const options = []
    const optionUs = []
    if (menuClients !== null) {
      const item = menuClients.childItems.nodes
      for (let i = 0; i < item.length; i += 1) {
        const { label, path } = item[i]
        options.push({ value: i, label, url: path, title: menuClients.label })
      }
    }
    if (menuUs !== null) {
      const item = menuUs.childItems.nodes
      for (let i = 0; i < item.length; i += 1) {
        const { label, path } = item[i]
        optionUs.push({ value: i, label, url: path, title: menuUs.label })
      }
    }
    setOptionsClients(options)
    setOptionsUs(optionUs)
    setAcordionItems([options, optionUs])
  }, [menuClients, menuUs])

  const handleClick = () => {
    setTimeout(() => {
      handleClose()
    }, 500)
  }

  const handleChange = panel => {
    if (expanded === panel) setExpanded("panel0")
    else setExpanded(panel)
  }

  return (
    <CSSTransition in={inProp} classNames={classNameID} timeout={inProp ? 500 : 250}>
      <ModuleStyled className={classNameID}>
        <Container>
          <Row>
            <Col xs={4} sm={8} md={10} lg={12} xl={12} offset={{ xs: 0, sm: 1, md: 0, lg: 1, xl: 1 }}>
              <div className={`${classNameID}__wrapper`}>
                <div className={`${classNameID}__button-close`} onClick={handleClose}>
                  <SvgClose stroke="#0178ED" strokeWidth="3" />
                </div>
                <div className={`${classNameID}__logo`}>
                  <Link to="/">
                    <SvgLogoLight />
                  </Link>
                </div>
                <div className={`${classNameID}__wrapper-elements`}>
                  {optionsClients.length > 0 && optionsUs.length > 0 && (
                    <div className={`${classNameID}__wrapper-accordion`}>
                      {accordionItems.map(items => {
                        countAccordion += 1
                        const panelName = `panel${countAccordion}`
                        let title = null
                        items.map(resp => {
                          title = resp.title
                        })
                        return <SelectMenu key={`accordion__${countAccordion}`} options={items} panelName={panelName} expanded={expanded} handleClose={handleClose} onChange={handleChange} defaultValue={title !== null ? title : ""} />
                      })}
                    </div>
                  )}
                  {menuGeneral !== null && (
                    <div className={`${classNameID}__box-border`}>
                      <div className={`${classNameID}__menu-general`}>
                        {menuGeneral.childItems.nodes.map(item => {
                          count += 1
                          if (isInternal(item.path)) {
                            return (
                              <MenuButton key={`button__${count}`} className={`${classNameID}__button`} to={item.path} onClick={handleClick}>
                                {item.label}
                              </MenuButton>
                            )
                          }
                          return (
                            <MenuButton key={`button-url__${count}`} className={`${classNameID}__button`} href={item.path} target="_blank" onClick={handleClick}>
                              {item.label}
                            </MenuButton>
                          )
                        })}
                      </div>
                      {menuLegals !== null &&
                        menuLegals.childItems.nodes.map(item => {
                          countLegals += 1
                          return (
                            <div key={`legales_${countLegals}`} className={`${classNameID}__legals`} onClick={handleClick}>
                              <Link to={item.path}> {item.label} </Link>
                            </div>
                          )
                        })}
                    </div>
                  )}
                  {menuClients !== null && (
                    <Visible md lg xl>
                      <div className={`${classNameID}__menus-right`}>
                        <p className={`${classNameID}__menu-title`}> {menuClients.label}</p>
                        {menuClients.childItems.nodes.map(item => {
                          countItems += 1
                          if (isInternal(item.path)) {
                            return (
                              <MenuButton key={`button__${countItems}`} className={`${classNameID}__button is-right`} to={item.path} onClick={handleClick}>
                                {item.label}
                              </MenuButton>
                            )
                          }
                          return (
                            <MenuButton key={`button-url__${countItems}`} className={`${classNameID}__button is-right`} href={item.path} target="_blank" onClick={handleClick}>
                              {item.label}
                            </MenuButton>
                          )
                        })}
                      </div>
                    </Visible>
                  )}
                  {menuUs !== null && (
                    <Visible md lg xl>
                      <div className={`${classNameID}__menus-right`}>
                        <p className={`${classNameID}__menu-title`}> {menuUs.label}</p>
                        {menuUs.childItems.nodes.map(item => {
                          countItems += 1
                          if (isInternal(item.path)) {
                            return (
                              <MenuButton key={`button__${countItems}`} className={`${classNameID}__button is-right`} to={item.path} onClick={handleClick}>
                                {item.label}
                              </MenuButton>
                            )
                          }
                          return (
                            <MenuButton key={`button-url__${countItems}`} className={`${classNameID}__button is-right`} href={item.path} target="_blank" onClick={handleClick}>
                              {item.label}
                            </MenuButton>
                          )
                        })}
                      </div>
                    </Visible>
                  )}
                </div>
                {socialUrls && (
                  <div className={`${classNameID}__wrapper-social`}>
                    <p> Siguenos en:</p>
                    <div className={`${classNameID}__container-icons`} onClick={handleClick}>
                      {socialUrls.map(link => {
                        count += 1
                        return (
                          <IconLink href={link.url} target="_blank" key={`social-media-link_${count}`}>
                            {link.icon === "facebook" && <SvgFacebook fill="#000" />}
                            {link.icon === "twitter" && <SvgTwitter fill="#000" />}
                            {link.icon === "instagram" && <SvgInstagram fill="#000" />}
                            {link.icon === "youtube" && <SvgYoutube fill="#000" />}
                          </IconLink>
                        )
                      })}
                    </div>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </ModuleStyled>
    </CSSTransition>
  )
}

HamburgerMenu.propTypes = {
  handleClose: func,
}

HamburgerMenu.defaultProps = {
  handleClose: () => null,
}

export default HamburgerMenu
