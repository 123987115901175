import React from 'react'
import { string, bool, func } from 'prop-types'

import NavDotStyled from './NavDot.style'

const NavDot = ({ isActive, onClick, className }) => {
  const classNameID = `e-dot`

  return (
    <NavDotStyled className={`${classNameID} ${className}`} onClick={onClick} isActive={isActive}>
      <span className={`${classNameID}__item`} />
    </NavDotStyled>
  )
}

NavDot.propTypes = {
  className: string,
  isActive: bool,
  onClick: func,
}

NavDot.defaultProps = {
  className: '',
  isActive: true,
  onClick: () => null,
}

export default NavDot
