import styled from 'styled-components'

import { device } from '../../../theme/mediaQueries'

const ModuleStyled = styled.div`
  position: relative;
  width: 100%;

  .slick-list {
    width: 100%;
  }
  .m-slider-block__container {
    position: relative;
    width: 100%;
  }

  @media ${device.sm} {
  }
`
export default ModuleStyled
