import styled from 'styled-components'

import { device } from '../../../theme/mediaQueries'

const ModuleStyled = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: 10px;
  margin: 0 auto;
  max-width: 360px;
  width: 100%;

  @media ${device.md} {
  }
`

export default ModuleStyled
