/* eslint-disable react/jsx-props-no-spreading */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

require('./src/styles/_slick/_slick.scss')
require('./src/styles/globals.scss')

const React = require('react')
const { gsap } = require('gsap');
const { ScrollTrigger } = require('gsap/ScrollTrigger');
const { ScrollToPlugin } = require('gsap/ScrollToPlugin')
const SEO = require('./src/components/seo/SEO').default
const Layout = require('./src/components/layout/Layout').default

exports.wrapPageElement = ({ element, props }) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)
  ScrollTrigger.defaults({
    ease: 'Power4.easeInOut',
  })
  return (
    <>
      <SEO {...props} />
      <Layout headerProperties={props.pageContext.pageProperties} pageContext={props.pageContext}>
        {element}
      </Layout>
    </>
  )
}

exports.onInitialClientRender = () => {
  setTimeout(() => {
    window.scrollTo(0, 0)
  }, 0)
}
