import React, { useRef, useState } from "react"
import Slider from "react-slick"
import { Container, Row, Col } from "react-awesome-styled-grid"
import { useSwipeable } from "react-swipeable"
import { number, node, string, shape, func, oneOf } from "prop-types"

import ModuleStyled from "./SliderBlock.style"
import SliderButtons from "./SliderButtons"

const SliderBlock = ({ children, total, settings, beforeChange, afterChange, buttonsConfig, className }) => {
  const classNameID = `m-slider-block`
  const [currentSlide, setCurrentSlide] = useState(1)
  const slider = useRef(null)

  const dots = []
  for (let i = 0; i < total; i += 1) dots.push(i)

  let timeoutInterval = 0
  let isInTrasition = false

  const update = next => {
    setCurrentSlide(next + 1)
  }
  const prevHandler = () => {
    if (!isInTrasition) {
      slider.current.slickPrev()
    }
  }
  const nextHandler = () => {
    if (!isInTrasition) {
      slider.current.slickNext()
    }
  }
  const goToHandler = (index, dontAnimate = false) => {
    if (!isInTrasition) slider.current.slickGoTo(index, dontAnimate)
  }
  const defaultSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    swipe: false,
    touchMove: false,
    draggable: false,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => {
      if (!isInTrasition) {
        update(next)
        beforeChange(current, next)
      }
    },
    afterChange: index => {
      clearTimeout(timeoutInterval)
      if (currentSlide !== index + 1) {
        update(index)
      }
      timeoutInterval = setTimeout(() => {
        isInTrasition = false
        afterChange(index)
      }, 250)
    },
  }
  const config = { trackMouse: true }
  const handlers = useSwipeable({
    onSwipedLeft: nextHandler,
    onSwipedRight: prevHandler,
    ...config,
  })
  const finalSettings = { ...defaultSettings, ...settings }

  if (total === 0) return null

  return (
    <ModuleStyled className={`${classNameID} ${className}`}>
      <div className={`${classNameID}__container`} {...handlers}>
        {finalSettings.columns ? (
          <Container>
            <Row>
              <Col {...finalSettings.columns}>
                <Slider ref={slider} {...finalSettings}>
                  {children}
                </Slider>
              </Col>
            </Row>
          </Container>
        ) : (
          <Slider ref={slider} {...finalSettings}>
            {children}
          </Slider>
        )}

        {buttonsConfig.type !== "none" && total > 1 && (
          <div className={`${classNameID}__buttons-container`}>
            {buttonsConfig.columns ? (
              <Container>
                <Row>
                  <Col {...buttonsConfig.columns}>
                    <SliderButtons currentSlide={currentSlide} total={total} prevHandler={prevHandler} nextHandler={nextHandler} goToHandler={goToHandler} className={`${classNameID}__buttons`} {...buttonsConfig} />
                  </Col>
                </Row>
              </Container>
            ) : (
              <SliderButtons currentSlide={currentSlide} total={total} prevHandler={prevHandler} nextHandler={nextHandler} goToHandler={goToHandler} className={`${classNameID}__buttons`} {...buttonsConfig} />
            )}
          </div>
        )}
      </div>
    </ModuleStyled>
  )
}

SliderBlock.propTypes = {
  children: node,
  total: number,
  settings: shape({
    columns: shape({
      xs: number,
      sm: number,
      md: number,
      lg: number,
      xl: number,
      offset: shape({
        xs: number,
        sm: number,
        md: number,
        lg: number,
        xl: number,
      }),
    }),
  }),
  buttonsConfig: shape({
    type: oneOf(["none", "progress", "buttons", "dots", "gallery"]),
    columns: shape({
      xs: number,
      sm: number,
      md: number,
      lg: number,
      xl: number,
      offset: shape({
        xs: number,
        sm: number,
        md: number,
        lg: number,
        xl: number,
      }),
    }),
  }),
  beforeChange: func,
  afterChange: func,
  className: string,
}

SliderBlock.defaultProps = {
  children: null,
  total: 0,
  settings: {},
  buttonsConfig: { type: "none", columns: null },
  beforeChange: () => null,
  afterChange: () => null,
  className: "",
}

export default SliderBlock
