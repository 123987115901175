import styled from 'styled-components'

const ModalBackground = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  .m-modal__overlay-btn {
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;

    &:hover {
      opacity: 1;
    }
  }
  .m-modal__overlay {
    display: block;
    background-color: rgba(10, 10, 10, 0.86);
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .m-modal__content {
    position: relative;
    width: 100%;
  }
`

export default ModalBackground
