import styled from 'styled-components'

import { device } from '../../theme/mediaQueries'

const LayoutStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  width: 100%;

  #l-layout__main {
    min-height: 500px;
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  #modal-root {
    top: 0;
    position: fixed;
    z-index: 9999;
  }
`
export default LayoutStyled

export const GridStyle = styled.div`
  //display: none;
  height: 100%;
  left: 50%;
  max-width: 1920px;
  opacity: 0.3;
  pointer-events: none;
  position: fixed;
  transform: translateX(-50%);
  width: 100%;
  z-index: 10000;
  
  @media ${device.sm} {
    > div {
      padding: 0 2.625rem;
    }
  }
  @media ${device.md} {
    > div {
      padding: 0 3.125rem;
    }
  }
  @media ${device.lg} {
    > div {
      padding: 0 3.75rem;
    }
  }
  @media ${device.xl} {
    > div {
      padding: 0 6.25rem;
    }
  }
`
