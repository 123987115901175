import styled from 'styled-components'

import { device } from '../../../theme/mediaQueries'

const FooterStyled = styled.footer`
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 10;

  .m-footer__row {
    position: relative;
  }
  .m-footer__bg-social,
  .m-footer__bg-segment,
  .m-footer__bg-policy {
    height: 100%;
    height: calc(100% + 1px);
    position: absolute;
    margin-left: -16px;
    width: 100%;
    width: calc(100% + 16px * 2);
    z-index: -1;
  }
  .m-footer__bg-social {
    background-color: ${props => props.theme.colors.footerBgSocial};
  }
  .m-footer__bg-segment {
    background-color: ${props => props.theme.colors.footerBgSegment};
  }
  .m-footer__bg-policy {
    border-top: 1px solid rgba(255, 255, 255, 0.6);
    background-color: ${props => props.theme.colors.footerBgSegment};
  }
  .m-footer__second-row {
    padding-bottom: 58px;
  }
  .m-footer__social-container {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: flex-end;

    .e-footer-icon-link {
      margin-left: 15px;
    }
    .e-footer-icon-link:first-child {
      margin-left: 0;
    }
  }
  .m-footer__social-title {
    color: ${props => props.theme.colors.white};
    display: none;
    font-family: ${props => props.theme.fonts.jeko};
    font-size: 14px;
    font-weight: ${props => props.theme.fonts.fontRegular};
    letter-spacing: 0.5px;
    line-height: 1;
  }
  .m-footer__logo {
    height: 48px;
    margin: 16px 0;
    width: 110px;
  }
  .m-footer__title-segment {
    color: ${props => props.theme.colors.white};
    display: inline-flex;
    font-family: ${props => props.theme.fonts.jeko};
    font-size: 18px;
    font-weight: ${props => props.theme.fonts.fontBold};
    letter-spacing: 0.5px;
    line-height: 1;
    margin-top: 48px;
  }
  .m-footer__first-segment,
  .m-footer__second-segment,
  .m-footer__third-segment {
    padding: 0 11%;

    .m-footer__segment-container {
      display: grid;
      grid-column-gap: 1rem;
      grid-template-columns: 1fr;
    }
    .e-footer-text-link {
      margin-top: 20px;
    }
  }
  .m-footer__policy-segment {
    display: flex;
    padding: 30px 11% 40px;

    .e-footer-text-link {
      position: relative;
      padding: 0 15px;

      &:after {
        background-color: ${props => props.theme.colors.white};
        content: ' ';
        height: 14px;
        position: absolute;
        right: 0;
        top: 0;
        width: 1px;
      }
    }
    .e-footer-text-link:first-child {
      padding-left: 0;
    }
    .e-footer-text-link:last-child {
      padding-right: 0;

      &:after {
        height: 0;
        width: 0;
      }
    }
  }

  @media (min-width: 360px) {
    .m-footer__first-segment,
    .m-footer__second-segment,
    .m-footer__third-segment {
      padding: 0 11%;
    }
  }

  @media (min-width: 550px) {
    .m-footer__first-segment,
    .m-footer__second-segment,
    .m-footer__third-segment {
      .m-footer__segment-container {
        grid-template-columns: 1fr 1fr;
      }
    }
  }

  @media ${device.sm} {
    .m-footer__bg-social,
    .m-footer__bg-segment,
    .m-footer__bg-policy {
      margin-left: -2.625rem;
      width: calc(100% + 2.625rem * 2);
    }
    .m-footer__second-row {
      padding-bottom: 55px;
    }
    .m-footer__logo {
      margin: 20px 0;
    }
    .m-footer__social-container {
      .e-footer-icon-link {
        margin-left: 16px;
      }
    }
    .m-footer__social-title {
      display: inline-flex;
      margin-right: 8px;
    }
    .m-footer__title-segment {
      font-size: 16px;
      letter-spacing: 0.5px;
      margin-bottom: 5px;
      margin-top: 55px;
    }
    .m-footer__first-segment,
    .m-footer__second-segment,
    .m-footer__third-segment {
      padding: 0;

      .m-footer__segment-container {
        grid-column-gap: 1.5rem;
      }
    }
    .m-footer__first-segment,
    .m-footer__second-segment {
      .m-footer__segment-container {
        grid-template-columns: auto;
      }
    }
    .m-footer__third-segment {
      .m-footer__segment-container {
        grid-template: repeat(${props => Math.ceil(props.totalInternalURLs / 2)}, auto) / repeat(2, auto);
        grid-auto-flow: column dense;
      }
    }
    .m-footer__policy-segment {
      justify-content: flex-end;
      padding: 30px 0;
    }
  }

  @media ${device.md} {
    .m-footer__bg-social,
    .m-footer__bg-segment,
    .m-footer__bg-policy {
      margin-left: -3.125rem;
      width: calc(100% + 3.125rem * 2);
    }
    .m-footer__second-row {
      padding-bottom: 52px;
    }
    .m-footer__social-title {
      font-size: 16px;
      margin-right: 12px;
    }
    .m-footer__title-segment {
      font-size: 18px;
      margin-bottom: 8px;
      margin-top: 52px;
    }
    .m-footer__policy-segment {
      justify-content: flex-end;
    }
  }

  @media ${device.lg} {
    .m-footer__bg-social,
    .m-footer__bg-segment,
    .m-footer__bg-policy {
      margin-left: -3.75rem;
      width: calc(100% + 3.75rem * 2);
    }
    .m-footer__first-segment,
    .m-footer__second-segment,
    .m-footer__third-segment {
      .e-footer-text-link {
        margin-top: 28px;
      }
    }
    .m-footer__logo {
      height: 57px;
      margin: 20px 0;
      width: 130px;
    }
    .m-footer__second-row {
      padding-bottom: 78px;
    }
    .m-footer__title-segment {
      margin-bottom: 5px;
      margin-top: 50px;
    }
    .m-footer__social-container {
      .e-footer-icon-link {
        margin-left: 18px;
      }
    }
    .m-footer__social-title {
      margin-right: 25px;
    }
    .m-footer__first-segment {
      .m-footer__segment-container {
        grid-template: repeat(${props => Math.ceil(props.totalAbout / (props.totalAbout > 3 ? 2 : 1))}, auto) / repeat(${props => (props.totalAbout > 3 ? 2 : 1)}, auto);
        grid-auto-flow: column dense;
      }
    }
    .m-footer__third-segment {
      .m-footer__segment-container {
        grid-template: repeat(${props => Math.ceil(props.totalInternalURLs / (props.totalInternalURLs > 6 ? 3 : 2))}, auto) / repeat(${props => (props.totalInternalURLs > 6 ? 3 : 2)}, auto);
      }
    }
    .m-footer__policy-segment {
      padding: 28px 0;
    }
  }

  @media ${device.xl} {
    .m-footer__bg-social,
    .m-footer__bg-segment,
    .m-footer__bg-policy {
      margin-left: -25vw;
      width: 150vw;
    }
    .m-footer__second-row {
      padding-bottom: 80px;
    }
    .m-footer__logo {
      margin: 18px 0;
    }
    .m-footer__social-container {
      .e-footer-icon-link {
        margin-left: 20px;
      }
    }
    .m-footer__social-title {
      margin-right: 5px;
    }
    .m-footer__title-segment {
      font-size: 22px;
      margin-bottom: 10px;
      margin-top: 56px;
    }
    .m-footer__policy-segment {
      padding: 32px 0;
    }
  }

  &.m-footer-enter {
    opacity: 0;
  }
  &.m-footer-enter-active {
    opacity: 1;
    transition: opacity 100ms;
  }
  &.m-footer-exit {
    opacity: 1;
  }
  &.m-footer-exit-active {
    opacity: 0;
    transition: opacity 250ms;
  }
`

export default FooterStyled
