import styled from 'styled-components'
import { Link } from 'gatsby'
import { device } from '../../../theme/mediaQueries'

export const ButtonStyled = styled(Link)`
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  outline: none;
  color: ${props => props.theme.colors.black};
  font-size: 22px;
  font-weight: ${props => props.theme.fonts.fontSemiBold};
  pointer-events: ${props => (props.isDisabled ? 'none' : 'auto')};
  position: relative;
  padding: 0px;
  height: 30px;
  width: max-content;

  svg{
      margin-right: 12px;
      width: 22px;
      display: none;
      path{
        stroke: ${props => props.theme.colors.pink};
      }
    }

  &:hover {
    cursor: pointer;
    color: ${props => props.theme.colors.blue};
    svg{
        display: block;
      }
  }
  &.is-disabled {
    pointer-events: none;
    opacity: 0.5;
  }

 



  @media ${device.md} {

  }

  @media ${device.lg} {
 
  }

  @media ${device.xl} {
    
  }
`

export const AnchorStyled = styled(ButtonStyled).attrs({ as: 'a' })``