import styled from 'styled-components'

import { device } from '../../../theme/mediaQueries'

const ModuleStyled = styled.div`
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  margin-bottom: 20px;
  transition: box-shadow 350ms;

  &.is-expanded {
    border-radius: 30px 0;
    box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.2);
    overflow: hidden;
    transition: box-shadow 1s;
  }

  &.is-Menu{
    border-radius: 0;
    box-shadow: none;
    overflow: hidden;
    transition: box-shadow 1s;
    margin: 0;
  }

  @media ${device.sm} {
  }

  @media ${device.md} {
  }

  @media ${device.lg} {
  }

  @media ${device.xl} {
  }
`
export default ModuleStyled
