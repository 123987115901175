import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Container, Row, Col } from 'react-awesome-styled-grid'
import { CSSTransition } from 'react-transition-group'

import { isInternal } from '../../../helpers/url'
import FooterTextLink from '../../elements/links/FooterTextLink'
import FooterIconLink from '../../elements/links/FooterIconLink'
import SvgLogoDark from '../../elements/svgs/SvgLogoDark'
import SvgFacebook from '../../elements/svgs/social/SvgFacebook'
import SvgTwitter from '../../elements/svgs/social/SvgTwitter'
import SvgInstagram from '../../elements/svgs/social/SvgInstagram'
import SvgYoutube from '../../elements/svgs/social/SvgYoutube'

import FooterStyled from './Footer.style'

const Footer = () => {
  const classNameID = `m-footer`
  const [inProp, setInProp] = useState(false)
  const {
    wpPost: {
      footer: { segmentedUrls, socialUrls, policyAndPrivacyUrls },
    },
  } = useStaticQuery(graphql`
    query FooterQuery {
      wpPost(slug: { eq: "footer" }) {
        footer {
          segmentedUrls {
            ... on WpPost_Footer_SegmentedUrls_Segment {
              title
              urls {
                title
                url
              }
            }
          }
          socialUrls {
            icon
            url
          }
          policyAndPrivacyUrls {
            title
            url
          }
        }
      }
    }
  `)
  const totalAbout = segmentedUrls[0].urls.length
  const totalInternalURLs = segmentedUrls[2].urls.length
  let count = -1
  let totalAboutCols = 3
  let totalExternalURLsCols = 3
  let totalInternalURLsCols = 4
  if (totalInternalURLs <= 6) {
    if (totalAbout > 3) {
      totalAboutCols = 4
      totalExternalURLsCols = 2
    }
  }
  if (totalInternalURLs > 6) {
    if (totalAbout <= 3) {
      totalAboutCols = 2
      totalExternalURLsCols = 2
      totalInternalURLsCols = 6
    } else {
      totalExternalURLsCols = 2
      totalInternalURLsCols = 5
    }
  }

  useEffect(() => {
    setInProp(true)
  }, [])

  return (
    <CSSTransition in={inProp} classNames={classNameID} timeout={inProp ? 100 : 250} unmountOnExit>
      <FooterStyled id={classNameID} className={classNameID} totalAbout={totalAbout} totalInternalURLs={totalInternalURLs}>
        <Container className={`${classNameID}__container`}>
          <Row className={`${classNameID}__row ${classNameID}__first-row`}>
            <div className={`${classNameID}__bg-social`} />
            <Col xs={2} sm={3} md={4} lg={4} xl={4}>
              <SvgLogoDark className={`${classNameID}__logo`} />
            </Col>
            <Col xs={2} sm={5} md={8} lg={8} xl={8}>
              <div className={`${classNameID}__social-container`}>
                <div className={`${classNameID}__social-title`}>Síguenos en:</div>
                {socialUrls.map(link => {
                  count += 1
                  return (
                    <FooterIconLink key={`key-footer-social-${count}`} href={link.url} target="_blank" className={`${classNameID}__social-link`}>
                      {link.icon === 'facebook' && <SvgFacebook fill="#FFF" />}
                      {link.icon === 'twitter' && <SvgTwitter fill="#FFF" />}
                      {link.icon === 'instagram' && <SvgInstagram fill="#FFF" />}
                      {link.icon === 'youtube' && <SvgYoutube fill="#FFF" />}
                    </FooterIconLink>
                  )
                })}
              </div>
            </Col>
          </Row>
          <Row className={`${classNameID}__row ${classNameID}__second-row`}>
            <div className={`${classNameID}__bg-segment`} />
            <Col xs={4} sm={2} md={3} lg={totalAboutCols} xl={totalAboutCols} offset={{ xs: 0, sm: 0, md: 0, lg: 1, xl: 1 }}>
              <div className={`${classNameID}__first-segment`}>
                <h4 className={`${classNameID}__title-segment`}>{segmentedUrls[0].title}</h4>
                <div className={`${classNameID}__segment-container`}>
                  {segmentedUrls[0].urls.map(link => {
                    count += 1
                    const url = {}
                    if (isInternal(link.url)) {
                      url.to = link.url
                    } else {
                      url.href = link.url
                      url.target = '_blank'
                    }
                    return (
                      <FooterTextLink key={`key-footer-segment-${count}`} {...url}>
                        {link.title}
                      </FooterTextLink>
                    )
                  })}
                </div>
              </div>
            </Col>
            <Col xs={4} sm={2} md={3} lg={totalExternalURLsCols} xl={totalExternalURLsCols}>
              <div className={`${classNameID}__second-segment`}>
                <h4 className={`${classNameID}__title-segment`}>{segmentedUrls[1].title}</h4>
                <div className={`${classNameID}__segment-container`}>
                  {segmentedUrls[1].urls.map(link => {
                    count += 1
                    const url = {}
                    if (isInternal(link.url)) {
                      url.to = link.url
                    } else {
                      url.href = link.url
                      url.target = '_blank'
                    }
                    return (
                      <FooterTextLink key={`key-footer-segment-${count}`} {...url}>
                        {link.title}
                      </FooterTextLink>
                    )
                  })}
                </div>
              </div>
            </Col>
            <Col xs={4} sm={4} md={6} lg={totalInternalURLsCols} xl={totalInternalURLsCols}>
              <div className={`${classNameID}__third-segment`}>
                <h4 className={`${classNameID}__title-segment`}>{segmentedUrls[2].title}</h4>
                <div className={`${classNameID}__segment-container`}>
                  {segmentedUrls[2].urls.map(link => {
                    count += 1
                    const url = {}
                    if (isInternal(link.url)) {
                      url.to = link.url
                    } else {
                      url.href = link.url
                      url.target = '_blank'
                    }
                    return (
                      <FooterTextLink key={`key-footer-segment-${count}`} {...url}>
                        {link.title}
                      </FooterTextLink>
                    )
                  })}
                </div>
              </div>
            </Col>
          </Row>
          <Row className={`${classNameID}__row ${classNameID}__third-row`}>
            <div className={`${classNameID}__bg-policy`} />
            <Col xs={4} sm={4} md={6} lg={6} xl={6} offset={{ xs: 0, sm: 4, md: 6, lg: 6, xl: 6 }}>
              <div className={`${classNameID}__policy-segment`}>
                {policyAndPrivacyUrls.map(link => {
                  count += 1
                  const url = {}
                  if (isInternal(link.url)) {
                    url.to = link.url
                  } else {
                    url.href = link.url
                    url.target = '_blank'
                  }
                  return (
                    <FooterTextLink key={`key-footer-policy-${count}`} {...url}>
                      {link.title}
                    </FooterTextLink>
                  )
                })}
              </div>
            </Col>
          </Row>
        </Container>
      </FooterStyled>
    </CSSTransition>
  )
}

export default Footer
