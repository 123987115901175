import React from 'react'
import { string, func, node, bool } from 'prop-types'

import ButtonStyled from './IconButton.style'

const IconButton = ({ children, onClick, onMouseOver, onMouseOut, isDisabled, className }) => {
  const classNameID = `e-icon-button`

  return (
    <ButtonStyled className={`${classNameID} ${className} ${isDisabled ? 'is-disabled' : ''}`} type="button" onClick={onClick} onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
      {children && <>{children}</>}
    </ButtonStyled>
  )
}

IconButton.propTypes = {
  children: node.isRequired,
  className: string,
  onClick: func,
  onMouseOver: func,
  onMouseOut: func,
  isDisabled: bool,
}

IconButton.defaultProps = {
  className: '',
  onClick: () => null,
  onMouseOver: () => null,
  onMouseOut: () => null,
  isDisabled: false,
}

export default IconButton
