exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-about-us-jsx": () => import("./../../../src/templates/aboutUs.jsx" /* webpackChunkName: "component---src-templates-about-us-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blogPost.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-blog-posts-jsx": () => import("./../../../src/templates/blogPosts.jsx" /* webpackChunkName: "component---src-templates-blog-posts-jsx" */),
  "component---src-templates-branch-offices-jsx": () => import("./../../../src/templates/branchOffices.jsx" /* webpackChunkName: "component---src-templates-branch-offices-jsx" */),
  "component---src-templates-contact-jsx": () => import("./../../../src/templates/contact.jsx" /* webpackChunkName: "component---src-templates-contact-jsx" */),
  "component---src-templates-formalities-jsx": () => import("./../../../src/templates/formalities.jsx" /* webpackChunkName: "component---src-templates-formalities-jsx" */),
  "component---src-templates-formalities-type-jsx": () => import("./../../../src/templates/formalitiesType.jsx" /* webpackChunkName: "component---src-templates-formalities-type-jsx" */),
  "component---src-templates-generic-jsx": () => import("./../../../src/templates/generic.jsx" /* webpackChunkName: "component---src-templates-generic-jsx" */),
  "component---src-templates-home-jsx": () => import("./../../../src/templates/home.jsx" /* webpackChunkName: "component---src-templates-home-jsx" */),
  "component---src-templates-new-contract-jsx": () => import("./../../../src/templates/newContract.jsx" /* webpackChunkName: "component---src-templates-new-contract-jsx" */),
  "component---src-templates-new-contract-type-jsx": () => import("./../../../src/templates/newContractType.jsx" /* webpackChunkName: "component---src-templates-new-contract-type-jsx" */),
  "component---src-templates-new-transparency-jsx": () => import("./../../../src/templates/newTransparency.jsx" /* webpackChunkName: "component---src-templates-new-transparency-jsx" */),
  "component---src-templates-online-contract-jsx": () => import("./../../../src/templates/onlineContract.jsx" /* webpackChunkName: "component---src-templates-online-contract-jsx" */),
  "component---src-templates-pensioners-jsx": () => import("./../../../src/templates/pensioners.jsx" /* webpackChunkName: "component---src-templates-pensioners-jsx" */),
  "component---src-templates-products-jsx": () => import("./../../../src/templates/products.jsx" /* webpackChunkName: "component---src-templates-products-jsx" */),
  "component---src-templates-rates-jsx": () => import("./../../../src/templates/rates.jsx" /* webpackChunkName: "component---src-templates-rates-jsx" */),
  "component---src-templates-report-jsx": () => import("./../../../src/templates/report.jsx" /* webpackChunkName: "component---src-templates-report-jsx" */),
  "component---src-templates-tender-process-jsx": () => import("./../../../src/templates/tenderProcess.jsx" /* webpackChunkName: "component---src-templates-tender-process-jsx" */),
  "component---src-templates-tender-processes-jsx": () => import("./../../../src/templates/tenderProcesses.jsx" /* webpackChunkName: "component---src-templates-tender-processes-jsx" */),
  "component---src-templates-vacants-jsx": () => import("./../../../src/templates/vacants.jsx" /* webpackChunkName: "component---src-templates-vacants-jsx" */)
}

