import * as React from 'react'
import { string, number, oneOfType } from 'prop-types'

function SvgWarningWithTriangle({ width, height, className }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} className={className} viewBox="0 0 551.2 463.8">
      <path fill="#FEBB00" d="M230 31.1 15.1 375.8C-7.2 411.6 18.5 458 60.7 458h429.7c42.2 0 67.9-46.4 45.6-82.2L321.2 31.1c-21-33.7-70.2-33.7-91.2 0z" />
      <path fill="#FED100" d="M275.6 5.8c-17.5 0-35.1 8.4-45.6 25.3L15.1 375.8C-7.2 411.6 18.5 458 60.7 458h214.8V5.8z" />
      <path fill="#3E6970" d="M298.6 287h-47c-3.4 0-6.2-2.8-6.2-6.2V143.4c0-3.4 2.8-6.2 6.2-6.2h47.6c3.6 0 6.5 2.9 6.5 6.5v136.1c.1 4-3.1 7.2-7.1 7.2zM298.3 377.2h-46.7c-3.4 0-6.2-2.8-6.2-6.2v-48c0-3.4 2.8-6.2 6.2-6.2h46.7c4.1 0 7.5 3.3 7.5 7.5v45.5c0 4-3.4 7.4-7.5 7.4z" />
      <path fill="#335A5A" d="M299.6 287h-23.7V137.2h23.7c3.4 0 6.2 2.8 6.2 6.2v137.4c0 3.4-2.8 6.2-6.2 6.2zM299.6 377.2h-23.7v-60.4h23.7c3.4 0 6.2 2.8 6.2 6.2v47.9c0 3.5-2.8 6.3-6.2 6.3z" />
    </svg>
  )
}

SvgWarningWithTriangle.propTypes = {
  height: oneOfType([string, number]),
  width: oneOfType([string, number]),
  className: string,
}

SvgWarningWithTriangle.defaultProps = {
  height: '1em',
  width: '1em',
  className: '',
}

export default React.memo(SvgWarningWithTriangle)
