import styled from 'styled-components'

import { device } from '../../../theme/mediaQueries'

const NavDotStyled = styled.button`
  background-color: transparent;
  border: none;
  height: ${props => (props.isActive ? '10px' : '8px')};
  outline: none;
  padding: 0;
  margin: 0 5px;
  width: ${props => (props.isActive ? '10px' : '8px')};

  .e-dot__item {
    background-color: ${props => (props.isActive ? props.theme.colors.blue : props.theme.colors.blueLight)};
    border-radius: 50%;
    display: block;
    height: 100%;
    width: 100%;
  }
  &:hover {
    cursor: pointer;
  }

  @media ${device.md} {
  }
`

export default NavDotStyled
