import React from 'react'
import { bool, node, string } from 'prop-types'

import ModuleStyled from './AccordionDetails.style'

const AccordionDetails = ({ children, expanded, className }) => {
  const classNameID = `m-accordion-details`

  return (
    <ModuleStyled className={`${classNameID} ${expanded ? 'is-expanded' : ''} ${className}`}>
      <div className={`${classNameID}__container`}>{children}</div>
    </ModuleStyled>
  )
}

AccordionDetails.propTypes = {
  children: node,
  expanded: bool,
  className: string,
}

AccordionDetails.defaultProps = {
  children: null,
  expanded: false,
  className: '',
}

export default AccordionDetails
