import styled from 'styled-components'

import { device } from '../../../theme/mediaQueries'

const ModuleStyled = styled.div`
  /* opacity: 0; */
  position: relative;

    .m-menu__wrapper{
        width: 100%;
        position: fixed;
        z-index: 1200   ;
        left: 0px;
        top: 0px;
        overflow: auto;
        transition: height 0.5s ease-out 0s;
        height: 100vh;
        display: block;
        background-color: ${props => props.theme.colors.white};
        padding: 0 3rem;
        

        .m-menu__logo{
          width: 100%;
          text-align: center;
          margin-top: 24px;
          margin-bottom: 40px;
          svg{
            width: 81px;
            height: 36px; 
            cursor: pointer;
          }

        }

        .m-menu__wrapper-elements{
            width: 100%;
            .m-menu__wrapper-accordion{
                margin-bottom: 40px;
            }
            .m-menu__menu-general{
                display: flex;
                flex-direction: column;
                margin-bottom: 60px;
                .m-menu__button{
                    margin-bottom: 30px;
                }
                .m-menu__button:last-child{
                    margin-bottom: 0;
                }
            }
            .m-menu__legals{
              margin-bottom: 30px;
              width: max-content;

              a{
                font-size: 14px;
                font-weight: ${props => props.theme.fonts.fontRegular};
                color: ${props => props.theme.colors.gray};
                letter-spacing: 0.25px;
                line-height: 1;
              }
            }
        }

        .m-menu__wrapper-social{
          width: 100%;
          border-top: 2px solid  ${props => props.theme.colors.grayLight};

          p{
            margin: 30px 0;
            font-size: 14px;
            font-weight: ${props => props.theme.fonts.fontRegular};
            color: ${props => props.theme.colors.gray};
            letter-spacing: 0.25px;
            line-height: 1;
          }

          .m-menu__container-icons{
            margin-bottom: 30px;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            align-items: center;
     
            svg{
              width: 24px;
              height: 24px;
            }
          }
        }

    }

    .m-menu__button-close{
        width: 35px;
        height: 35px;
        position: absolute;
        top: 30px;
        left: 16px;
        border-radius: 50%;
        color: ${props => props.theme.colors.blue};
        background-color: #E5F1FD;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        :hover{
          opacity: 0.7;
        }
    }

  @media ${device.sm} {

    .m-menu__button-close{
      left: 30px;
      width: 45px;
      height: 45px;
    }

    .m-menu__wrapper{
      padding: 0 8rem;
      .m-menu__logo{
        margin-top: 30px;
        margin-bottom: 78px;
        svg{
          width: 95px;
          height: 42px;
        }
      }

      .m-menu__wrapper-elements{
        .m-menu__wrapper-accordion{
          margin-bottom: 50px;
        }

        .m-menu__menu-general{
          margin-bottom: 70px;
          .m-menu__button{
            font-size: 24px;
            margin-bottom: 40px;
          }
        }

        .m-menu__legals{
              margin-bottom: 45px;
              a{
                font-size: 16px;
              }
        }
      }

      .m-menu__wrapper-social{
        p{
          margin-top: 40px;
          font-size: 16px;
        }
        .m-menu__container-icons{
          max-width: 240px;
          margin-bottom: 40px;
        }
      }
    }

  }

  @media ${device.md} {
    .m-menu__button-close{
      left: 38px;
    }

    .m-menu__wrapper{
      padding: 0 4.8rem;

      .m-menu__wrapper-elements{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 40px;

        .m-menu__box-border{
          border-right: 2px solid #E5F1FD;
          margin-right: 10px
        }

        .m-menu__wrapper-accordion{
          display: none;
        }

        .m-menu__menu-general{
          margin-bottom: 90px;
          .m-menu__button{
            font-size: 22px;
            margin-bottom: 40px;
          }
        }

        .m-menu__legals{
            margin-bottom: 0px;
        }

        .m-menu__menus-right{
          display: flex;
          flex-direction: column;

          .m-menu__menu-title{
            font-size: 32px;
            font-weight: ${props => props.theme.fonts.fontBold};
            color: ${props => props.theme.colors.blue};
            margin-bottom: 45px;
            position: relative;
            line-height: 0.9;

            &::before{
              background-color: ${props => props.theme.colors.pink};
              bottom: -15px;
              content: '';
              display: block;
              height: 2px;
              position: absolute;
              left: 0;
              width: 30px;
            }
          }

          .m-menu__button{
            font-size: 18px;
            margin-bottom: 40px;
          }
          .m-menu__button:last-child{
            margin-bottom: 0;
          }
        }
      }

      .m-menu__wrapper-social{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        position: relative;
        top: -60px;
        border: none;
        width: 30%;
        left: 70%;
        p{
          margin-top: 0px;
          margin-bottom: 20px;
          font-size: 14px;
        }
        .m-menu__container-icons{
          max-width: 200px;
          width: 200px;
          margin-bottom: 0px;
          justify-items: end;
        }
      }
    }
  }

  @media ${device.lg} {
    .m-menu__button-close{
      width: 52px;
      height: 52px;
      left: 48px;
      top: 60px;
      svg{
        width: 20px;
        height: 20px;
      }
    }

    .m-menu__wrapper{
      padding: 30px 10.5% 0 10.5%;

      .m-menu__logo{
        margin-top: 30px;
        margin-bottom: 85px;
        svg{
          width: 103px;
          height: 46px;
        }
      }

      .m-menu__wrapper-elements{
        .m-menu__box-border{
          margin-right: 50px
        }
        .m-menu__menu-general{
          margin-bottom: 110px;
          .m-menu__button{
            font-size: 24px;
            margin-bottom: 40px;
          }
        }

        .m-menu__menus-right{
          .m-menu__menu-title{
            &::before{
              width: 35px;
              height: 3px;
            }
          }

        }
      }

      .m-menu__wrapper-social{
        p{
          font-size: 16px;
        }
        .m-menu__container-icons{
          max-width: 240px;
          width: 240px;
        }
      }
    }
  }

  @media ${device.xl} {
    .m-menu__button-close{
      width: 55px;
      height: 55px;
      left: 130px;
      top: 50px;
    }

    .m-menu__wrapper{
      padding: 0 16%;

      .m-menu__logo{
        margin-top: 50px;
        margin-bottom: 120px;
        svg{
          width: 122px;
          height: 54px;
        }
      }

      .m-menu__wrapper-elements{
        grid-column-gap: 95px;
        .m-menu__box-border{
          margin-right: 50px
        }
        .m-menu__menu-general{
          margin-bottom: 140px;
          .m-menu__button{
            font-size: 28px;
            margin-bottom: 50px;
          }
        }

        .m-menu__legals{
              a{
                font-size: 18px;
              }
        }

        .m-menu__menus-right{
          .m-menu__menu-title{
            font-size: 42px;
            margin-bottom: 70px;

            &::before{
              width: 40px;
              height: 3px;
            }
          }

          .m-menu__button{
            font-size: 20px;
            margin-bottom: 45px;
          }
          .m-menu__button:last-child{
            margin-bottom: 0;
          }
        }
      }

      .m-menu__wrapper-social{
        top: -75px;
        p{
          margin-bottom: 25px;
          font-size: 18px;
        }
        .m-menu__container-icons{
          max-width: 300px;
          width: 300px;
          
          svg{
            width: 32px;
            height: 32px;
          }
        }
      }

    }
  }

  &.m-menu-enter {
    /* opacity: 0; */
  }
  &.m-menu-enter-active {
    /* opacity: 1; */
    /* transition: opacity 500ms; */
  }
  &.m-menu-enter-done {
    /* opacity: 1; */
  }
  &.m-menu-exit {
    /* opacity: 1; */
  }
  &.m-menu-exit-active {
    /* opacity: 0; */
    /* transition: opacity 250ms; */
  }
`

export default ModuleStyled