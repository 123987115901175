import styled from 'styled-components'

import { device } from '../../../theme/mediaQueries'

const ProgressBarStyled = styled.div`
  position: relative;
  width: 100%;

  .e-progress-bar__bar {
    background-color: ${props => props.theme.colors.white};
    display: block;
    height: 3px;
    opacity: 0.6;
    width: 100%;
  }
  .e-progress-bar__progress {
    background-color: ${props => props.theme.colors.white};
    bottom: 0;
    height: 5px;
    position: absolute;
    width: ${props => (props.percent ? props.percent : 0)}%;
    transition: width 250ms;
  }

  @media ${device.sm} {
  }
`

export default ProgressBarStyled
