import * as React from 'react'

function SvgArrowMenu() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24.56 13.7"
      xmlSpace="preserve"
    >
      <g
        clipRule="evenodd"
        fill="none"
        stroke="#222221"
        strokeWidth={2}
        strokeMiterlimit={10}
      >
        <path d="M0 6.85h23M17 .86l5.99 5.99L17 12.84" />
      </g>
    </svg>
  )
}

export default React.memo(SvgArrowMenu)