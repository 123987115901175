import styled from 'styled-components'

import { device } from '../../../theme/mediaQueries'

const StyleMenuContainer = styled.div`

  .m-select-menu__accordion-details{
    display: flex;
    flex-direction: column;
    .m-select-menu__button{
        margin-bottom: 30px;
        font-size: 16px;
    }
    .m-select-menu__button:first-child{
        margin-top: 30px;
    }
    .m-select-menu__button:last-child{
        margin-bottom: 15px;
    }
  }

 @media ${device.sm}{

 }

 @media ${device.md}{

 }

 @media ${device.lg}{

 }

 @media ${device.xl}{

 }

`

export default StyleMenuContainer