import styled from 'styled-components'

import { device } from '../../../theme/mediaQueries'

const ButtonStyled = styled.button`
  background-color: transparent;
  border: none;
  display: inline-flex;
  outline: none;
  pointer-events: ${props => (props.isDisabled ? 'none' : 'auto')};
  padding: 0px;

  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
  &.is-disabled {
    pointer-events: none;
    opacity: 0.6;
  }

  @media ${device.md} {
  }
`

export default ButtonStyled
