import styled from 'styled-components'

import { device } from '../../../theme/mediaQueries'

const ModuleStyled = styled.div`
  position: relative;
`

export const ProgressStyled = styled.div`
  .m-slider-buttons__wrapper {
    position: relative;
  }
  .m-slider-buttons__container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
  }
  .m-slider-buttons__numbers {
    align-items: center;
    display: flex;
    width: 65px;
  }
  .m-slider-buttons__current {
    color: ${props => props.theme.colors.white};
    font-family: ${props => props.theme.fonts.greycliffCF};
    font-size: 19px;
    font-weight: ${props => props.theme.fonts.fontBold};
    line-height: 1;
    letter-spacing: 1px;
  }
  .m-slider-buttons__separator-numbers,
  .m-slider-buttons__total {
    color: ${props => props.theme.colors.white};
    font-family: ${props => props.theme.fonts.greycliffCF};
    font-size: 14px;
    font-weight: ${props => props.theme.fonts.fontMedium};
    line-height: 1;
    letter-spacing: 1px;
    opacity: 0.6;
  }
  .m-slider-buttons__separator-numbers {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .m-slider-buttons__buttons {
    align-items: center;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 26px;
    position: relative;
  }
  .m-slider-buttons__prev-btn,
  .m-slider-buttons__next-btn {
    position: relative;
  }
  .m-slider-buttons__hitarea {
    height: 30px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 30px;
  }
  .m-slider-buttons__svg-prev,
  .m-slider-buttons__svg-next {
    height: 7px;
    position: relative;
    width: 14px;
  }
  .m-slider-buttons__svg-prev {
    transform: rotate(90deg);
  }
  .m-slider-buttons__svg-next {
    transform: rotate(-90deg);
  }
  .m-slider-buttons__separator-buttons {
    background-color: ${props => props.theme.colors.white};
    display: block;
    height: 12px;
    width: 2px;
  }

  @media ${device.sm} {
    .m-slider-buttons__numbers {
      width: 75px;
    }
    .m-slider-buttons__current {
      font-size: 20px;
    }
    .m-slider-buttons__separator-numbers,
    .m-slider-buttons__total {
      font-size: 16px;
    }
    .m-slider-buttons__buttons {
      grid-gap: 20px;
    }
  }

  @media ${device.xl} {
    .m-slider-buttons__numbers {
      width: 85px;
    }
    .m-slider-buttons__current {
      font-size: 22px;
    }
    .m-slider-buttons__separator-numbers,
    .m-slider-buttons__total {
      font-size: 18px;
    }
    .m-slider-buttons__buttons {
      grid-gap: 26px;
    }
    .m-slider-buttons__hitarea {
      height: 40px;
      width: 40px;
    }
    .m-slider-buttons__svg-prev,
    .m-slider-buttons__svg-next {
      height: 10px;
      width: 20px;
    }
    .m-slider-buttons__separator-buttons {
      height: 18px;
    }
  }
`

export const ButtonsStyled = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  .m-slider-buttons__prev-btn,
  .m-slider-buttons__next-btn {
    background-color: ${props => props.theme.colors.blue};
    height: 52px;
    position: relative;
    width: 52px;
  }
  .m-slider-buttons__prev-btn {
    border-radius: 52px 0 52px 52px;
  }
  .m-slider-buttons__next-btn {
    border-radius: 0 52px 52px 52px;
  }
  .m-slider-buttons__svg-prev,
  .m-slider-buttons__svg-next {
    height: 16px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 16px;
  }
  .m-slider-buttons__svg-prev {
    transform: rotate(90deg);
    transform-origin: top center;
  }
  .m-slider-buttons__svg-next {
    transform: rotate(-90deg);
    transform-origin: center left;
  }

  @media ${device.xl} {
    .m-slider-buttons__prev-btn,
    .m-slider-buttons__next-btn {
      height: 66px;
      width: 66px;
    }
    .m-slider-buttons__prev-btn {
      border-radius: 66px 0 66px 66px;
    }
    .m-slider-buttons__next-btn {
      border-radius: 0 66px 66px 66px;
    }
    .m-slider-buttons__svg-prev,
    .m-slider-buttons__svg-next {
      height: 18px;
      width: 18px;
    }
  }
`

export const DotsStyled = styled.div``

export const NumbersStyled = styled.div`
  .m-slider-buttons__wrapper {
    position: relative;
  }
  .m-slider-buttons__container {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 18px;
  }
  .m-slider-buttons__numbers {
    align-items: center;
    display: flex;
    width: 65px;
  }
  .m-slider-buttons__current {
    color: ${props => props.theme.colors.blue};
    font-family: ${props => props.theme.fonts.greycliffCF};
    font-size: 18px;
    font-weight: ${props => props.theme.fonts.fontBold};
    line-height: 1;
    letter-spacing: 1px;
  }
  .m-slider-buttons__separator-numbers,
  .m-slider-buttons__total {
    color: ${props => props.theme.colors.blue};
    font-family: ${props => props.theme.fonts.greycliffCF};
    font-size: 18px;
    font-weight: ${props => props.theme.fonts.fontBold};
    line-height: 1;
    letter-spacing: 1px;
  }
  .m-slider-buttons__separator-numbers {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    color: ${props => props.theme.colors.pink}
  }
  .m-slider-buttons__buttons {
    align-items: center;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 40px;
    position: relative;
  }
  .m-slider-buttons__prev-btn,
  .m-slider-buttons__next-btn {
    position: relative;
  }
  .m-slider-buttons__hitarea {
    height: 30px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 30px;
  }
  .m-slider-buttons__svg-prev,
  .m-slider-buttons__svg-next {
    height: 7px;
    position: relative;
    width: 14px;
  }
  .m-slider-buttons__svg-prev {
    transform: rotate(90deg);
  }
  .m-slider-buttons__svg-next {
    transform: rotate(-90deg);
  }
  .m-slider-buttons__separator-buttons {
    background-color: ${props => props.theme.colors.white};
    display: block;
    height: 12px;
    width: 2px;
  }

  @media ${device.sm} {
    .m-slider-buttons__container{
      justify-content: flex-start;
    }
    .m-slider-buttons__numbers {
      width: 75px;
    }
    .m-slider-buttons__current {
      font-size: 18px;
    }
    .m-slider-buttons__separator-numbers,
    .m-slider-buttons__total {
      font-size: 18px;
    }
    .m-slider-buttons__buttons {
      grid-gap: 28px;
    }
  }

  @media ${device.lg} {
   
    .m-slider-buttons__current {
      font-size: 20px;
    }
    .m-slider-buttons__separator-numbers,
    .m-slider-buttons__total {
      font-size: 20px;
    }
    .m-slider-buttons__buttons {
      grid-gap: 36px;
    }
    .m-slider-buttons__hitarea {
      height: 40px;
      width: 40px;
    }
    .m-slider-buttons__svg-prev,
    .m-slider-buttons__svg-next {
      height: 10px;
      width: 20px;
    }
    .m-slider-buttons__separator-buttons {
      height: 18px;
    }
  }

  @media ${device.lg} {
   
   .m-slider-buttons__current {
     font-size: 22px;
   }
   .m-slider-buttons__separator-numbers,
   .m-slider-buttons__total {
     font-size: 22px;
   }
   .m-slider-buttons__buttons {
     grid-gap: 36px;
   }

 }
`

export default ModuleStyled
