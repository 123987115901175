import React from 'react'
import { bool, string, node, func } from 'prop-types'

import { AnchorStyled, LinkStyled } from './FooterIconLink.style'

const FooterIconLink = ({ children, to, href, onClick, target, isDisabled, className }) => {
  const classNameID = `e-footer-icon-link`

  if (to) {
    return (
      <LinkStyled to={to} onClick={onClick} className={`${classNameID} ${className} ${isDisabled ? 'is-disabled' : ''}`}>
        {children && <>{children}</>}
      </LinkStyled>
    )
  }
  return (
    <AnchorStyled href={href} onClick={onClick} target={target} rel="noopener noreferrer" className={`${classNameID} ${className} ${isDisabled ? 'is-disabled' : ''}`}>
      {children && <>{children}</>}
    </AnchorStyled>
  )
}

FooterIconLink.propTypes = {
  children: node.isRequired,
  to: string,
  href: string,
  onClick: func,
  target: string,
  isDisabled: bool,
  className: string,
}

FooterIconLink.defaultProps = {
  to: null,
  href: '#',
  onClick: () => null,
  target: '_self',
  isDisabled: false,
  className: '',
}

export default FooterIconLink
