import styled from 'styled-components'

import { device } from '../../../theme/mediaQueries'

const ModuleStyled = styled.div`
  overflow: hidden;
  position: relative;
  max-height: 0;
  transition: max-height 350ms;
  width: 100%;

  &.is-expanded {
    max-height: 1000px;
    transition: max-height 2s 350ms;
  }
  .m-accordion-details__container {
    max-height: 1000px;
    overflow: auto;
    overflow-y: auto;
  }

  @media ${device.sm} {
  }

  @media ${device.md} {
  }

  @media ${device.lg} {
  }

  @media ${device.xl} {
  }
`
export default ModuleStyled
