import * as React from 'react'
import { string, number, oneOfType } from 'prop-types'

const SvgTwitter = ({ width, height, fill, className }) => (
  <svg width={width} height={height} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 530 438" xmlSpace="preserve">
    <path fill={fill} d="M259.7 136.5c0-15.6-2.2-30.4.3-44.3 7.7-42 32.7-70.8 73.3-83.9 40.3-13.1 76.9-4.1 108.3 24.3 3.8 3.4 6.9 4.5 11.9 3.2 19.3-5.2 38-11.8 55.5-21.3 1.5-.8 3-1.5 6.4-3.2-8.6 25.9-23.8 44.5-45.1 58.6.3.7.7 1.4 1 2.1 18.9-5.3 38-10.6 57.5-15.9-1.6 2.3-3.1 4.7-4.8 7.1-12.3 17-26.7 31.8-43.4 44.4-3.8 2.9-5.2 5.7-5.2 10.7-1 117.5-48.9 210.3-148.4 274-54.5 34.9-115.8 46.5-180 42.8-49.2-2.8-95-17-137.6-41.9-2.9-1.7-5.7-3.4-8.4-5.7 37.6 2.9 73.9-2 108.7-16.5 17.4-7.2 33.6-16.6 49.6-29.1-49.7-4.6-82.8-29.7-101.3-76.3h47c.1-.7.2-1.5.3-2.2-31.9-8.4-56-26.6-71.7-55.4-8.7-15.9-13.2-33.2-13-52.3 15.1 7.3 30.2 12.4 47.3 12.9C21.7 127.5 7.5 80.4 36 22.7c59.3 69.2 132.7 107.9 223.7 113.8z" />
  </svg>
)

SvgTwitter.propTypes = {
  height: oneOfType([string, number]),
  width: oneOfType([string, number]),
  fill: string,
  className: string,
}

SvgTwitter.defaultProps = {
  height: '1em',
  width: '1em',
  fill: '#000',
  className: '',
}

export default SvgTwitter
