import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
	body {
		color: ${props => props.theme.colors.black};
		font-family: ${props => props.theme.font};
		font-size: 16px;
		font-weight: ${props => props.theme.fonts.fontRegular};
		padding: 0;
		margin: 0;
	}
	.body-hidden{
		overflow: hidden
	}
	p, h1, h2, h3, h4, h5, h6 {
		margin: 0;
	}
	ul {
		margin: 0;
		padding: 0;
		li {
			list-style: none;
		}
	}
	div,
	section,
	article {
		box-sizing: border-box;
	}
	img {
		-webkit-user-drag: none;
		user-select: none;
	}
	svg {
		pointer-events: none;
		-webkit-user-drag: none;
		user-select: none;
	}
	a {
		text-decoration: none;
	}
	.u-overflow-hidden {
		overflow: hidden;	
		overscroll-behavior-x: none;
	}
	.u-overflow-hidden-ios {
		height: 100%;
		overscroll-behavior-x: none;
		position: fixed;
	}
`

export default GlobalStyles
