import React from "react"
import { string, func, node, bool } from "prop-types"
import SvgArrowMenu from "../svgs/SvgArrowMenu"

import { ButtonStyled, AnchorStyled } from "./MenuButton.style"

const MenuButton = ({ to, href, target, children, onClick, isDisabled, className }) => {
  const classNameID = `e-menu-button`

  if (to !== null) {
    return (
      <ButtonStyled to={to} className={`${classNameID} ${className} ${isDisabled ? "is-disabled" : ""}`} type="button" onClick={onClick}>
        {children && <> <SvgArrowMenu className={`${classNameID}__arrow`} /> {children}</>}
      </ButtonStyled>
    )
  }
  return (
    <AnchorStyled href={href} target={target} className={`${classNameID} ${className} ${isDisabled ? "is-disabled" : ""}`} type="button" onClick={onClick}>
      {children && <> <SvgArrowMenu className={`${classNameID}__arrow`} /> {children}</>}
    </AnchorStyled>
  )
}

MenuButton.propTypes = {
  children: node.isRequired,
  className: string,
  onClick: func,
  isDisabled: bool,
  to: string,
  href: string,
  target: string,
}

MenuButton.defaultProps = {
  className: "",
  onClick: () => null,
  isDisabled: false,
  to: null,
  href: null,
  target: "",
}

export default MenuButton
