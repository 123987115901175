import React, { useRef, useState, useEffect } from 'react'
import { Container, Row, Col, Visible } from 'react-awesome-styled-grid'
import lottie from 'lottie-web'
import { shape } from 'prop-types'

import incidentsData from '../../../../static/assets/lotties/incidents.json'
import useScrollPosition from '../../../hooks/useScrollPosition'
import useWindowSize from '../../../hooks/useWindowSize'
import { getAllIncidents } from './api/api'
import SliderBlock from '../slider/SliderBlock'
import Modal from '../modal/modal'
import IconButton from '../../elements/buttons/IconButton'
import SvgClose from '../../elements/svgs/SvgClose'
import SvgWarningWithTriangle from '../../elements/svgs/SvgWarningWithTriangle'

import ModuleStyled, { ModalStyled } from './Incidents.style'

const Incidents = ({ pageContext }) => {
  const classNameID = `m-incidents`
  const incidentsContainerRef = useRef(null)
  const incidentsRef = useRef(null)
  const [footer, setFooter] = useState(null)
  const [incidents, setIncidents] = useState([])
  const [current, setCurrent] = useState(0)
  const [isModalOpen, setModalIsOpen] = useState(false)
  const scrollPosition = useScrollPosition()
  const size = useWindowSize()

  let count = -1
  let countBtns = -1

  const handleClick = () => {
    setModalIsOpen(true)
  }
  const handleClose = () => {
    setModalIsOpen(false)
  }

  useEffect(() => {
    if (footer && incidentsContainerRef.current) {
      const footerTopPosition = footer.offsetTop
      const srollPos = scrollPosition + size.innerHeight
      if (srollPos > footerTopPosition) {
        if (!incidentsContainerRef.current.classList.contains('no-sticky')) incidentsContainerRef.current.classList.add('no-sticky')
      } else {
        incidentsContainerRef.current.classList.remove('no-sticky')
      }
    }
  }, [size, scrollPosition, footer, incidentsContainerRef])

  useEffect(() => {
    getAllIncidents(data => {
      setIncidents(data.data)
    })
  }, [pageContext])

  useEffect(() => {
    let incidentLottie

    if (incidents.length > 0 && incidentsRef.current && !incidentLottie) {
      incidentLottie = lottie.loadAnimation({
        container: incidentsRef.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: incidentsData,
      })
    }

    return () => {
      if (incidentLottie) {
        incidentLottie.stop()
        incidentLottie.destroy()
        incidentLottie = null
      }
    }
  }, [incidents, incidentsRef.current])

  useEffect(() => {
    if (!footer) {
      const theFooter = document.getElementById('m-footer')
      setFooter(theFooter)
    }
    return () => {
      if (incidentsContainerRef.current) incidentsContainerRef.classList.remove('no-sticky')
    }
  }, [])

  return (
    <ModuleStyled className={classNameID} ref={incidentsContainerRef}>
      {incidents.length > 0 && (
        <IconButton className={`${classNameID}__open-btn`} onClick={handleClick}>
          <div className={`${classNameID}__lottie-container`}>
            <div className={`${classNameID}__lottie`} ref={incidentsRef} />
          </div>
        </IconButton>
      )}
      {isModalOpen && (
        <Modal closeModalHandler={handleClose}>
          <ModalStyled>
            <Container>
              <Row>
                <Col xs={4} sm={6} md={8} lg={8} xl={6} offset={{ xs: 0, sm: 1, md: 2, lg: 2, xl: 3 }}>
                  <div className={`${classNameID}__modal-container`}>
                    <IconButton className={`${classNameID}__close-btn`} onClick={handleClose}>
                      <SvgClose className={`${classNameID}__close-icon`} stroke="#FFFFFF" strokeWidth={4} />
                    </IconButton>
                    <div className={`${classNameID}__header`}>
                      <SvgWarningWithTriangle className={`${classNameID}__warning-icon`} />
                      <h3 className={`${classNameID}__title-header`}>Incidencias</h3>
                    </div>
                    <div className={`${classNameID}__container`}>
                      <Visible xs>
                        <SliderBlock total={incidents.length} buttonsConfig={{ type: 'dots' }}>
                          {incidents.map(incident => {
                            count += 1
                            return (
                              <div key={`key-incident-${count}`} className={`${classNameID}__incident`}>
                                <h4 className={`${classNameID}__title`}>{incident.title}</h4>
                                <div className={`${classNameID}__description`} dangerouslySetInnerHTML={{ __html: incident.description }} />
                              </div>
                            )
                          })}
                        </SliderBlock>
                      </Visible>
                      <Visible sm md lg xl>
                        <div className={`${classNameID}__left`}>
                          {incidents.map(incident => {
                            countBtns += 1
                            const currentIndex = countBtns
                            return (
                              <IconButton
                                key={`key-incident-${countBtns}`}
                                onClick={() => {
                                  setCurrent(currentIndex)
                                }}
                                className={`${classNameID}__btn ${currentIndex === current ? 'is-active' : ''}`}
                              >
                                {incident.title}
                              </IconButton>
                            )
                          })}
                        </div>
                        <div className={`${classNameID}__right`}>
                          <div className={`${classNameID}__description`} dangerouslySetInnerHTML={{ __html: incidents[current].description }} />
                        </div>
                      </Visible>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </ModalStyled>
        </Modal>
      )}
    </ModuleStyled>
  )
}

Incidents.propTypes = {
  pageContext: shape({}),
}

Incidents.defaultProps = {
  pageContext: {},
}

export default Incidents
