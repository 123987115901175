import React from 'react'
import { bool, node, func, string } from 'prop-types'

import SvgDownArrow from '../../elements/svgs/SvgDownArrow'

import ModuleStyled from './AccordionSummary.style'

const AccordionSummary = ({ children, expanded, onClick, className }) => {
  const classNameID = `m-accordion-summary`

  return (
    <ModuleStyled className={`${classNameID} ${expanded ? 'is-expanded' : ''} ${className}`} onClick={onClick}>
      <div className={`${classNameID}__content`}>{children}</div>
      <div className={`${classNameID}__arrow`}>
        <SvgDownArrow fill={`${expanded ? '#FFF' : '#0178ED'}`} />
      </div>
    </ModuleStyled>
  )
}

AccordionSummary.propTypes = {
  children: node,
  expanded: bool,
  onClick: func,
  className: string,
}

AccordionSummary.defaultProps = {
  children: null,
  expanded: false,
  onClick: () => null,
  className: '',

}

export default AccordionSummary
