import { useState } from 'react'

const useSiteData = () => {
  const [isHome, setIsHome] = useState(false)

  return {
    isHome,
    setIsHome
  }
}

export default useSiteData
