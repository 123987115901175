import styled from 'styled-components'
import { Link } from 'gatsby'

import { device } from '../../../theme/mediaQueries'

export const LinkStyled = styled(Link)`
  color: ${props => props.theme.colors.white};
  display: inline-flex;
  font-family: ${props => props.theme.fonts.jeko};
  font-size: 14px;
  font-weight: ${props => props.theme.fonts.fontRegular};
  letter-spacing: 0.25px;
  line-height: 1;
  opacity: 0.6;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  &.is-disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  @media ${device.sm} {
    font-size: 13px;
  }

  @media ${device.md} {
    font-size: 14px;
  }

  @media ${device.xl} {
    font-size: 18px;
  }
`
export const AnchorStyled = styled(LinkStyled).attrs({ as: 'a' })``
