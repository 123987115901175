import * as React from 'react'
import { string, number, oneOfType } from 'prop-types'

const SvgYoutube = ({ width, height, fill, className }) => (
  <svg width={width} height={height} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 530 372" xmlSpace="preserve">
    <path fill={fill} d="M520 91.8v187.8c-.4 1.5-.9 3.2-1.1 4.8-5.4 34.8-24 60.1-56 74.9-9 4.2-19.1 5.9-28.8 8.8H95.8c-1.1-.4-2.2-1-3.4-1.1-38.5-5.7-69.3-33.4-79-71-1.4-5.3-2.2-10.8-3.4-16.2V92c.4-1.4.9-2.8 1.1-4.2 6.1-46.1 44.1-82.5 90.4-82.9 108.9-.9 217.9-.8 326.8 0 41.6.3 77.3 30.6 88 70.9 1.6 5.1 2.5 10.5 3.7 16zm-188.1 94.5c-45-26.3-88.8-52-133.5-78.2v156.5c44.7-26.2 88.5-52 133.5-78.3z" />
  </svg>
)

SvgYoutube.propTypes = {
  height: oneOfType([string, number]),
  width: oneOfType([string, number]),
  fill: string,
  className: string,
}

SvgYoutube.defaultProps = {
  height: '1em',
  width: '1em',
  fill: '#000',
  className: '',
}

export default SvgYoutube
