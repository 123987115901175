import * as React from 'react'
import { string, number, oneOfType } from 'prop-types'

const SvgFacebook = ({ width, height, fill, className }) => (
  <svg width={width} height={height} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 274 530" xmlSpace="preserve">
    <path fill={fill} d="M270 13v81c-20.1.3-40.3.1-60.4 1-16.4.7-26.6 9.3-30.8 24.5-1.1 3.8-1.7 7.9-1.7 11.8-.2 20.5-.3 65.3-.3 65.3h89.9l-11.8 91h-78.2L177 521H83l-.2-233.6-78.7-.4v-91l78.7-.1s-.8-60.5 1.6-87.3c4.5-50.1 35.3-86.8 86-96.6 27.9-5.2 85.6-1.7 99.6 1z" />
  </svg>
)

SvgFacebook.propTypes = {
  height: oneOfType([string, number]),
  width: oneOfType([string, number]),
  fill: string,
  className: string,
}

SvgFacebook.defaultProps = {
  height: '1em',
  width: '1em',
  fill: '#000',
  className: '',
}

export default SvgFacebook
