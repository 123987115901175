import React from 'react'
import { bool, func, node, string } from 'prop-types'

import ModuleStyled from './Accordion.style'

const Accordion = ({ children, onChange, expanded, className }) => {
  const classNameID = `m-accordion`

  return (
    <ModuleStyled className={`${classNameID} ${expanded ? 'is-expanded' : ''} ${className}`} onClick={onChange}>
      {children}
    </ModuleStyled>
  )
}

Accordion.propTypes = {
  children: node,
  onChange: func,
  expanded: bool,
  className: string,
}

Accordion.defaultProps = {
  children: null,
  onChange: () => null,
  expanded: false,
  className: '',
}

export default Accordion
