import styled from 'styled-components'

import { device } from '../../../theme/mediaQueries'

const ModuleStyled = styled.h3`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 10px;
  .m-accordion-header__title {
    font-family: ${props => props.theme.fonts.jeko};
    font-size: 26px;
    font-weight: ${props => props.theme.fonts.fontBold};
    line-height: 1.22;
  }
  &.is-expanded {
    .mm-accordion-header__title {
      color: ${props => props.theme.colors.white};
    }
  }

  @media ${device.sm} {
    grid-column-gap: 12px;
    .m-accordion-header__title {
      line-height: 1.55;
      font-size: 32px;
    }
  }

  @media ${device.md} {
  }

  @media ${device.lg} {
  }

  @media ${device.xl} {
  }
`
export default ModuleStyled
