import styled from 'styled-components'
import { Link } from 'gatsby'

import { device } from '../../../theme/mediaQueries'

export const LinkStyled = styled(Link)`
  display: inline-flex;
  font-size: 0;

  img {
    height: 52px;
    object-fit: contain;
    width: 52px;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
  &.is-disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  @media ${device.md} {
  }
`
export const AnchorStyled = styled(LinkStyled).attrs({ as: 'a' })``
