import React, { useEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'
// import { ScreenBadge } from 'react-awesome-styled-grid'
// import GridDebugger from 'react-grid-debugger'
import { bool, node, shape, string } from 'prop-types'

import useSiteNav from '../../hooks/useSiteNav'
import SiteContext from '../../context/site.context'

import GlobalStyles from '../../theme/globalStyles'
import themeDefault from '../../theme/themeDefault'
import Header from '../modules/header/Header'
import Incidents from '../modules/incidents/Incidents'
import Footer from '../modules/footer/Footer'

// import LayoutStyled, { GridStyle } from './Layout.style'
import LayoutStyled from './Layout.style'

const Layout = ({ children, headerProperties, pageContext }) => {
  const classNameID = `l-layout`
  const siteNav = useSiteNav()
  const [headerType, setHeaderType] = useState(null)
  const [showIncidents, setShowIncidents] = useState(null)

  useEffect(() => {
    if (headerProperties) {
      setHeaderType(headerProperties.headerType)
      setShowIncidents(headerProperties.showIncidents)
    }

    return () => {
      window.scrollTo(0, 0)
    }
  }, [headerProperties])

  return (
    <SiteContext.Provider value={siteNav}>
      <ThemeProvider theme={themeDefault}>
        {/* <GridStyle>
          <GridDebugger show maxWidth="1920" theme={themeDefault} numCols={[4, 8, 12, 12, 12]} gutter={{ 320: '1rem', 768: '1.5rem', 1024: '1.5rem', 1360: '1.5rem', 1920: '1.5rem' }} css style={{ pointerEvents: 'none', opacity: 0.5, position: 'absolute', width: '100%', height: '100%', zIndex: 0 }} />
        </GridStyle>
        <ScreenBadge /> */}
        <GlobalStyles />
        <LayoutStyled className={`${classNameID}`}>
          <Header headerType={headerType} />
          <main id={`${classNameID}__main`}>
            {children}
            {showIncidents && <Incidents pageContext={pageContext} />}
          </main>
          <Footer />
          <div id="modal-root" />
        </LayoutStyled>
      </ThemeProvider>
    </SiteContext.Provider>
  )
}

Layout.propTypes = {
  children: node.isRequired,
  headerProperties: shape({
    headerType: string,
    showIncidents: bool,
  }),
  pageContext: shape({}),
}

Layout.defaultProps = {
  headerProperties: {
    headerType: 'dark',
    showIncidents: false,
  },
  pageContext: {},
}

export default Layout
