import * as React from 'react'
import { string, number, oneOfType } from 'prop-types'

const SvgClose = ({ width, height, stroke, strokeWidth, className }) => (
  <svg width={width} height={height} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlSpace="preserve">
    <path stroke={stroke} strokeWidth={strokeWidth} strokeLinecap="round" fill="none" className="st0" d="m2 2 15.9 15.9M2 17.9 17.9 2" />
  </svg>
)

SvgClose.propTypes = {
  height: oneOfType([string, number]),
  width: oneOfType([string, number]),
  stroke: string,
  strokeWidth: oneOfType([string, number]),
  className: string,
}

SvgClose.defaultProps = {
  height: '1em',
  width: '1em',
  stroke: '#000',
  strokeWidth: '2.5',
  className: '',
}

export default SvgClose
