import styled from 'styled-components'
import { Link } from 'gatsby'

import { device } from '../../../theme/mediaQueries'

export const LinkStyled = styled(Link)`
  align-items: center;
  background-color: ${props => props.theme.colors.footerBgIconLink};
  border-radius: 50%;
  display: flex;
  height: 38px;
  justify-content: center;
  min-width: 38px;
  width: 38px;

  .gatsby-image-wrapper,
  svg,
  img {
    height: 20px;
    width: 20px;
  }
  img {
    object-fit: contain !important;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  &.is-disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  @media ${device.sm} {
    height: 40px;
    width: 40px;
  }

  @media ${device.md} {
    height: 42px;
    width: 42px;

    .gatsby-image-wrapper,
    svg,
    img {
      height: 22px;
      width: 22px;
    }
  }

  @media ${device.lg} {
    height: 44px;
    width: 44px;

    .gatsby-image-wrapper,
    svg,
    img {
      height: 24px;
      width: 24px;
    }
  }

  @media ${device.xl} {
    height: 46px;
    width: 46px;
  }
`
export const AnchorStyled = styled(LinkStyled).attrs({ as: 'a' })``
