import styled from 'styled-components'

import { device } from '../../../theme/mediaQueries'

const ModuleStyled = styled.div`
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 5;

  .m-incidents__open-btn {
    bottom: 27px;
    pointer-events: auto;
    position: fixed;
    right: 27px;

    &:hover {
      opacity: 1;
    }
  }
  .m-incidents__title {
    color: ${props => props.theme.colors.white};
    font-family: ${props => props.theme.fonts.jeko};
    font-size: 14px;
    font-weight: ${props => props.theme.fonts.fontSemiBold};
    letter-spacing: 0.2px;
    line-height: 1;
    pointer-events: none;
  }
  .m-incidents__lottie-container {
    height: 30px;
    position: relative;
    width: 30px;
  }
  .m-incidents__lottie {
    height: 230%;
    left: 50%;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 230%;
  }
  &.no-sticky {
    height: 100%;

    .m-incidents__open-btn {
      position: absolute;
    }
  }

  @media ${device.sm} {
    .m-incidents__open-btn {
      bottom: 45px;
      right: 45px;
    }
    .m-incidents__lottie-container {
      height: 35px;
      width: 35px;
    }
  }

  @media ${device.md} {
    .m-incidents__open-btn {
      bottom: 50px;
      right: 50px;
    }
  }

  @media ${device.lg} {
    .m-incidents__open-btn {
      bottom: 60px;
      right: 60px;
    }
    .m-incidents__title {
      font-size: 16px;
    }
    .m-incidents__lottie-container {
      height: 40px;
      width: 40px;
    }
  }

  @media ${device.xl} {
    .m-incidents__open-btn {
      bottom: 120px;
      right: 120px;
    }
    .m-incidents__title {
      font-size: 18px;
    }
  }
`

export default ModuleStyled

export const ModalStyled = styled.div`
  width: 100%;

  .m-incidents__modal-container {
    align-items: center;
    background-color: ${props => props.theme.colors.white};
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    padding: 40px 15px;
    pointer-events: auto;
    position: relative;
    width: auto;
  }
  .m-incidents__close-btn {
    align-items: center;
    display: inline-flex;
    background-color: ${props => props.theme.colors.pink};
    border-radius: 50%;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.2);
    height: 44px;
    justify-content: center;
    position: absolute;
    top: -22px;
    transition: box-shadow 250ms ease-in-out;
    width: 44px;

    &:hover {
      opacity: 1;
      box-shadow: none;
    }
  }
  .m-incidents__close-icon {
    height: 18px;
    width: 18px;
  }
  .m-incidents__header {
    align-items: center;
    border-bottom: 2px solid ${props => props.theme.colors.grayLight};
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
    width: 100%;
  }
  .m-incidents__warning-icon {
    height: 24px;
    margin-right: 15px;
    width: 29px;
  }
  .m-incidents__title-header {
    color: ${props => props.theme.colors.blue};
    font-family: ${props => props.theme.fonts.jeko};
    font-size: 28px;
    font-weight: ${props => props.theme.fonts.fontBold};
    height: 24px;
    line-height: 1;
  }
  .m-incidents__container {
    width: 100%;
  }
  .m-incidents__incident {
    margin-top: 24px;
    padding: 0 8%;
  }
  .m-incidents__title {
    color: ${props => props.theme.colors.blue};
    display: block;
    font-family: ${props => props.theme.fonts.jeko};
    font-size: 16px;
    font-weight: ${props => props.theme.fonts.fontSemiBold};
    line-height: 1.25;
    margin-bottom: 38px;
    position: relative;
    text-align: center;

    &:after {
      background-color: ${props => props.theme.colors.pink};
      bottom: -15px;
      content: '';
      display: block;
      height: 2px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      width: 18px;
    }
  }
  .m-incidents__description {
    font-family: ${props => props.theme.fonts.jeko};
    font-size: 14px;
    font-weight: ${props => props.theme.fonts.fontRegular};
    line-height: 1.71;
    margin-bottom: 40px;
    text-align: center;

    p {
      margin-bottom: 10px;
    }
    p:last-child {
      margin-bottom: 0;
    }
    strong {
      font-weight: ${props => props.theme.fonts.fontSemiBold};
    }
  }

  @media ${device.sm} {
    .m-incidents__modal-container {
      height: 420px;
      margin-left: -1.5rem;
      margin-right: -1.5rem;
      padding: 44px 30px 30px;
    }
    .m-incidents__container {
      display: grid;
      grid-template-columns: 210px 1fr;
      grid-column-gap: 50px;
      height: 300px;
      padding-top: 30px;
    }
    .m-incidents__left,
    .m-incidents__right {
      overflow: auto;
      padding: 18px 25px 18px 0;
    }
    .m-incidents__left {
      border-right: 2px solid ${props => props.theme.colors.grayLight};

      .m-incidents__btn:last-child {
        margin-bottom: 0;
      }
    }
    .m-incidents__btn {
      color: rgba(0, 0, 0, 0.68);
      display: block;
      font-family: ${props => props.theme.fonts.jeko};
      font-size: 14px;
      font-weight: ${props => props.theme.fonts.fontSemiBold};
      line-height: 1.42;
      margin-bottom: 18px;
      position: relative;
      text-align: left;

      &.is-active {
        color: ${props => props.theme.colors.blue};
        font-size: 16px;
        line-height: 1.25;
        pointer-events: none;
        margin-bottom: 28px;

        &:after {
          background-color: ${props => props.theme.colors.pink};
          bottom: -6px;
          content: '';
          display: block;
          height: 2px;
          position: relative;
          width: 18px;
        }
      }
    }
    .m-incidents__description {
      margin-bottom: 0;
      text-align: left;
    }
  }

  @media ${device.md} {
    .m-incidents__modal-container {
      height: 400px;
      padding: 45px 40px;
    }
    .m-incidents__close-btn {
      height: 46px;
      top: -23px;
      width: 46px;
    }
    .m-incidents__close-icon {
      height: 20px;
      width: 20px;
    }
    .m-incidents__container {
      grid-template-columns: 215px 1fr;
      grid-column-gap: 65px;
      height: 264px;
      padding-top: 35px;
    }
    .m-incidents__left {
      padding: 20px 35px 20px 0;
    }
    .m-incidents__right {
      padding: 18px 40px 18px 0;
    }
    .m-incidents__description {
      font-size: 16px;
      line-height: 1.625;
    }
  }

  @media ${device.lg} {
    .m-incidents__modal-container {
      height: 450px;
      margin-left: 4.5%;
      margin-right: 4.5%;
      padding: 54px 40px 30px;
    }
    .m-incidents__close-btn {
      height: 54px;
      top: -27px;
      width: 54px;
    }
    .m-incidents__close-icon {
      height: 22px;
      width: 22px;
    }
    .m-incidents__header {
      padding-bottom: 30px;
    }
    .m-incidents__warning-icon {
      margin-right: 20px;
    }
    .m-incidents__title-header {
      font-size: 32px;
      height: 25px;
    }
    .m-incidents__container {
      grid-template-columns: 235px 1fr;
      grid-column-gap: 80px;
      height: 309px;
      padding-top: 30px;
    }
    .m-incidents__left {
      padding: 25px 24px 25px 0;
    }
    .m-incidents__right {
      padding: 23px 24px 23px 0;
    }
    .m-incidents__btn {
      font-size: 16px;
      line-height: 1.375;
      margin-bottom: 25px;

      &.is-active {
        font-size: 18px;
        line-height: 1.22;
        margin-bottom: 30px;

        &:after {
          bottom: -8px;
        }
      }
    }
  }

  @media ${device.xl} {
    .m-incidents__modal-container {
      height: 480px;
      margin-left: 0;
      margin-right: 0;
      padding: 50px 60px 40px;
    }
    .m-incidents__header {
      padding-bottom: 25px;
    }
    .m-incidents__warning-icon {
      height: 30px;
      margin-right: 18px;
      width: 36px;
    }
    .m-incidents__title-header {
      font-size: 36px;
      height: 29px;
    }
    .m-incidents__container {
      grid-template-columns: 260px 1fr;
      grid-column-gap: 90px;
      height: 333px;
      padding-top: 40px;
    }
    .m-incidents__left {
      padding: 10px 55px 10px 0;
    }
    .m-incidents__right {
      padding: 24px 60px 24px 0;
    }
    .m-incidents__btn {
      font-size: 18px;
      line-height: 1.44;
      margin-bottom: 30px;

      &.is-active {
        font-size: 20px;
        line-height: 1.2;
        margin-bottom: 35px;

        &:after {
          bottom: -10px;
        }
      }
    }
    .m-incidents__description {
      font-size: 18px;
      line-height: 1.55;
    }
  }
`
