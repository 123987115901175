import React from "react"
import { string, number, func, oneOf } from "prop-types"

import ProgressBar from "../../elements/progress/ProgressBar"
import NavDots from "../../elements/dots/NavDots"
import IconButton from "../../elements/buttons/IconButton"
import SvgDownArrow from "../../elements/svgs/SvgDownArrow"

import ModuleStyled, { ProgressStyled, DotsStyled, ButtonsStyled, NumbersStyled } from "./SliderButtons.style"

const SliderButtons = ({ type, currentSlide, total, prevHandler, nextHandler, goToHandler, className }) => {
  const classNameID = `m-slider-buttons`
  const percent = (currentSlide / total) * 100

  if (type === "none") return null

  return (
    <ModuleStyled className={`${classNameID} ${className}`}>
      {type === "progress" && (
        <ProgressStyled className={`${classNameID}__wrapper`}>
          <div className={`${classNameID}__container`}>
            <div className={`${classNameID}__numbers`}>
              <span className={`${classNameID}__current`}>{currentSlide < 10 ? `0${currentSlide}` : currentSlide}</span>
              <span className={`${classNameID}__separator-numbers`}>/</span>
              <span className={`${classNameID}__total`}>{total < 10 ? `0${total}` : total}</span>
            </div>
            <div className={`${classNameID}__buttons`}>
              <IconButton type="button" onClick={prevHandler} className={`${classNameID}__prev-btn`}>
                <span className={`${classNameID}__hitarea`} />
                <SvgDownArrow fill="#FFF" className={`${classNameID}__svg-prev`} />
              </IconButton>
              <span className={`${classNameID}__separator-buttons`} />
              <IconButton type="button" onClick={nextHandler} className={`${classNameID}__next-btn`}>
                <span className={`${classNameID}__hitarea`} />
                <SvgDownArrow fill="#FFF" className={`${classNameID}__svg-next`} />
              </IconButton>
            </div>
          </div>
          <div className={`${classNameID}__progress`}>
            <ProgressBar percent={percent} className={`${classNameID}__progress-bar`} />
          </div>
        </ProgressStyled>
      )}
      {type === "buttons" && (
        <ButtonsStyled className={`${classNameID}__buttons`}>
          <IconButton type="button" onClick={prevHandler} className={`${classNameID}__prev-btn`}>
            <SvgDownArrow fill="#FFF" className={`${classNameID}__svg-prev`} />
          </IconButton>
          <IconButton type="button" onClick={nextHandler} className={`${classNameID}__next-btn`}>
            <SvgDownArrow fill="#FFF" className={`${classNameID}__svg-next`} />
          </IconButton>
        </ButtonsStyled>
      )}
      {type === "dots" && (
        <DotsStyled className={`${classNameID}__dots`}>
          <NavDots total={total} currentDot={currentSlide} goToHandler={goToHandler} />
        </DotsStyled>
      )}
      {type === "gallery" && (
        <NumbersStyled className={`${classNameID}__wrapper`}>
          <div className={`${classNameID}__container`}>
            <div className={`${classNameID}__buttons`}>
              <IconButton type="button" onClick={prevHandler} className={`${classNameID}__prev-btn`}>
                <span className={`${classNameID}__hitarea`} />
                <SvgDownArrow fill="#0178ED" className={`${classNameID}__svg-prev`} />
              </IconButton>
              <div className={`${classNameID}__numbers`}>
                <span className={`${classNameID}__current`}>{currentSlide < 10 ? `0${currentSlide}` : currentSlide}</span>
                <span className={`${classNameID}__separator-numbers`}>/</span>
                <span className={`${classNameID}__total`}>{total < 10 ? `0${total}` : total}</span>
              </div>
              <IconButton type="button" onClick={nextHandler} className={`${classNameID}__next-btn`}>
                <span className={`${classNameID}__hitarea`} />
                <SvgDownArrow fill="#0178ED" className={`${classNameID}__svg-next`} />
              </IconButton>
            </div>
          </div>
        </NumbersStyled>
      )}
    </ModuleStyled>
  )
}

SliderButtons.propTypes = {
  type: oneOf(["none", "progress", "buttons", "dots", "gallery"]),
  currentSlide: number,
  total: number,
  prevHandler: func,
  nextHandler: func,
  goToHandler: func,
  className: string,
}

SliderButtons.defaultProps = {
  type: "none",
  currentSlide: 1,
  total: 1,
  prevHandler: () => null,
  nextHandler: () => null,
  goToHandler: () => null,
  className: "",
}

export default SliderButtons
