/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https: //www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import { shape, string } from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql, withPrefix } from 'gatsby'

function SEO(props) {
  const {
    pageContext: { seo },
    location,
    lang,
  } = props
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          titleTemplate
          description
          image
          siteUrl
        }
      }
    }
  `)

  const canonical = `${site.siteMetadata.siteUrl}${location ? location.pathname : ''}`
  const params = {
    title: site.siteMetadata.title,
    description: site.siteMetadata.description,
    image: site.siteMetadata.image,
    url: canonical,
    twitterTitle: site.siteMetadata.title,
    twitterDescription: site.siteMetadata.description,
    twitterImage: site.siteMetadata.image,
  }

  if (seo) {
    if (seo.opengraphTitle && seo.opengraphTitle !== '') {
      params.title = seo.opengraphTitle
      params.twitterTitle = seo.opengraphTitle
    }
    if (seo.opengraphDescription && seo.opengraphDescription !== '') {
      params.description = seo.opengraphDescription
      params.twitterDescription = seo.opengraphDescription
    }
    if (seo.opengraphImage) {
      params.image = seo.opengraphImage.sourceUrl
      params.twitterImage = seo.opengraphImage.sourceUrl
    }
    if (seo.twitterTitle && seo.twitterTitle !== '') params.twitterTitle = seo.twitterTitle
    if (seo.twitterDescription && seo.twitterDescription !== '') params.twitterDescription = seo.twitterDescription
    if (seo.twitterImage) params.twitterImage = seo.twitterImage.sourceUrl
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={params.title}
      defaultTitle={site.siteMetadata.title}
      titleTemplate={site.siteMetadata.titleTemplate}
    >
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="title" content={params.title} />
      <meta name="description" content={params.description} />
      <meta name="image" content={params.image} />
      <meta name="type" content="website" />
      <meta name="application-name" content={site.siteMetadata.title} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Página de Aguas de Saltillo" />
      <meta property="og:url" content={canonical} />
      <meta property="og:title" content={params.title} />
      <meta property="og:description" content={params.description} />
      <meta property="og:image" content={params.image} />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={canonical} />
      <meta property="twitter:title" content={params.twitterTitle} />
      <meta property="twitter:description" content={params.twitterDescription} />
      <meta property="twitter:image" content={params.twitterImage} />
      <link rel="canonical" href={canonical} />
      <link rel="shortcut icon" type="image/png" href={`${withPrefix('/')}assets/metas/favicon.ico`} />
      <link rel="apple-touch-icon-precomposed" sizes="57x57" href={`${withPrefix('/')}assets/metas/apple-touch-icon-57x57.png`} />
      <link rel="apple-touch-icon-precomposed" sizes="114x114" href={`${withPrefix('/')}assets/metas/apple-touch-icon-114x114.png`} />
      <link rel="apple-touch-icon-precomposed" sizes="72x72" href={`${withPrefix('/')}assets/metas/apple-touch-icon-72x72.png`} />
      <link rel="apple-touch-icon-precomposed" sizes="144x144" href={`${withPrefix('/')}assets/metas/apple-touch-icon-144x144.png`} />
      <link rel="apple-touch-icon-precomposed" sizes="60x60" href={`${withPrefix('/')}assets/metas/apple-touch-icon-60x60.png`} />
      <link rel="apple-touch-icon-precomposed" sizes="120x120" href={`${withPrefix('/')}assets/metas/apple-touch-icon-120x120.png`} />
      <link rel="apple-touch-icon-precomposed" sizes="76x76" href={`${withPrefix('/')}assets/metas/apple-touch-icon-76x76.png`} />
      <link rel="apple-touch-icon-precomposed" sizes="152x152" href={`${withPrefix('/')}assets/metas/apple-touch-icon-152x152.png`} />
      <link rel="icon" type="image/png" href={`${withPrefix('/')}assets/metas/favicon-196x196.png`} sizes="196x196" />
      <link rel="icon" type="image/png" href={`${withPrefix('/')}assets/metas/favicon-96x96.png`} sizes="96x96" />
      <link rel="icon" type="image/png" href={`${withPrefix('/')}assets/metas/favicon-32x32.png`} sizes="32x32" />
      <link rel="icon" type="image/png" href={`${withPrefix('/')}assets/metas/favicon-16x16.png`} sizes="16x16" />
      <link rel="icon" type="image/png" href={`${withPrefix('/')}assets/metas/favicon-128.png`} sizes="128x128" />
      <meta name="msapplication-TileColor" content="#FFFFFF" />
      <meta name="msapplication-TileImage" content={`${withPrefix('/')}assets/metas/mstile-144x144.png`} />
      <meta name="msapplication-square70x70logo" content={`${withPrefix('/')}assets/metas/mstile-70x70.png`} />
      <meta name="msapplication-square150x150logo" content={`${withPrefix('/')}assets/metas/mstile-150x150.png`} />
      <meta name="msapplication-square310x310logo" content={`${withPrefix('/')}assets/metas/mstile-310x310.png`} />
    </Helmet>
  )
}

SEO.propTypes = {
  pageContext: shape({
    opengraphTitle: string,
    opengraphDescription: string,
    opengraphImage: shape({ sourceUrl: string }),
    twitterTitle: string,
    twitterDescription: string,
    twitterImage: shape({ sourceUrl: string }),
  }),
  location: shape({
    pathname: string,
  }),
  lang: string,
}

SEO.defaultProps = {
  pageContext: {},
  location: {},
  lang: `es`,
}

export default SEO
