import React from 'react'
import { string, number, func } from 'prop-types'

import NavDot from './NavDot'

import ModuleStyled from './NavDots.style'

const NavDots = ({ total, currentDot, goToHandler, className }) => {
  const classNameID = `e-nav-dots`
  const dots = []
  for (let i = 0; i < total; i += 1) dots.push(i)

  return (
    <ModuleStyled className={`${classNameID} ${className}`}>
      {dots.map(value => (
        <NavDot key={`key-nav-dots-${value}`} onClick={() => goToHandler(value)} isActive={currentDot === value + 1} />
      ))}
    </ModuleStyled>
  )
}

NavDots.propTypes = {
  className: string,
  total: number,
  currentDot: number,
  goToHandler: func,
}

NavDots.defaultProps = {
  className: '',
  total: 1,
  currentDot: 0,
  goToHandler: () => null,
}

export default NavDots
