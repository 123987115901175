import * as React from 'react'
import { string, number, oneOfType } from 'prop-types'

function SvgDownArrow({ width, height, fill, className }) {
  return (
    <svg width={width} height={height} className={className} viewBox="0 0 20 10" xmlns="http://www.w3.org/2000/svg">
      <path fill={fill} d="M0 2.5C3.3 5 6.7 7.5 10 10c3.3-2.5 6.7-5 10-7.5L17.5 0 10 5.4 2.5 0 0 2.5z" />
    </svg>
  )
}

SvgDownArrow.propTypes = {
  fill: string,
  height: oneOfType([string, number]),
  width: oneOfType([string, number]),
  className: string,
}

SvgDownArrow.defaultProps = {
  fill: '#000',
  height: 10,
  width: 20,
  className: '',
}

export default React.memo(SvgDownArrow)
