import styled from 'styled-components'
import { device } from '../../../theme/mediaQueries'

const HeaderStyled = styled.header`
  position: absolute;
  width: 100%;
  z-index: 1000;
  left: 50%;
  top: 12px;
  transform: translateX(-50%);

  .headerContainer {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
  }
  .headerContainer__menu {
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover{
      opacity: 0.7;
    }

    svg{
      width: 20px;
      height: 16px;
    }

  &.menuDark{
      svg path{
          stroke: ${props => props.theme.colors.blue};
        }
    }
  }
  
  .headerContainer__navBar {
    border-radius: 35px 0 35px 0;
    background-color: ${props => props.theme.colors.white};
    min-width: 89%;
    display: flex;
    justify-content: space-between;
    max-width: 1600px;
    position: relative;

    .container-logo, .logoAguasSaltillo {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 81px;
      height: 36px;
    }
    .navBar-buttons .m-header__link{
      text-decoration: none;
      font-family: ${props => props.theme.fonts.greycliffCF};
    }

    &.headerDark{
      background-color: ${props => props.theme.colors.blue};

      .navBar-buttons .m-header__link{
        color: ${props => props.theme.colors.white};
      }
    }
  }
  .headerContainer__navBar-payContainer {
    background-color: ${props => props.theme.colors.pink};
    border-radius: 35px 0 35px 0;
    min-width: 24%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .headerContainer__navBar-payButton {
      color: ${props => props.theme.colors.white};
      font-weight: ${props => props.theme.fonts.fontBold};
      font-size: 14px;
      img {
        width: 28px;
        height: 28px;
      }

      &:hover{
        opacity: 0.8;
      }
    }
  }

  @media ${device.sm} {
    top: 17px;

    .headerContainer {
      min-width: 90.5%;
      height: 70px;
    }
    .headerContainer__navBar {
      min-width: 90.5%;
      
      .container-logo, .logoAguasSaltillo{
        width: 95px;
        height: 42px;
      }
    }

    .headerContainer__menu{
      svg{
        width: 26px;
      }
    }
  }

  @media ${device.md} {
    top: 20px;

    .headerContainer {
      height: 82px;
    }
    .headerContainer__navBar {
      flex-direction: row;
      align-items: center;
      width: 94%;

      .container-logo, .logoAguasSaltillo{
        left: 47%;
      }

      .navBar-buttons {
        padding-left: 20px;
        .m-header__link {
          color: ${props => props.theme.colors.blue};
          font-weight: ${props => props.theme.fonts.fontMedium};
          background-color: transparent;
          border: none;
          padding: 0;
          margin-right: 25px;
          font-size: 16px;

          &:hover{
            opacity: 0.8;
          }
        }
      }
    }
    .headerContainer__navBar-payContainer {
      min-width: 21%;

      .headerContainer__navBar-payButton{
        font-size: 16px;
        text-decoration: none;
      }
    }
  }

  @media ${device.lg} {
    top: 22px;

    .headerContainer {
      height: 85px;
    }

    .headerContainer__menu{
      svg{
        width: 32px;
      }
    }

    .headerContainer__navBar {
      width: 93.5%;

      .navBar-buttons{
        padding-left: 30px;

        .m-header__link{
          margin-right: 40px;
        }
      }
      
      .container-logo, .logoAguasSaltillo{
        width: 103px;
        height: 46px;
        left: 46.5%;
      }
    }
    .headerContainer__navBar-payContainer {
      min-width: 20%;
    }
  }

  @media ${device.xl} {
    top: 30px;

    .headerContainer {
      height: 100px;
    }
    .headerContainer__navBar {
      width: 93.5%;

      .navBar-buttons .m-header__link{
        font-size: 20px;
      }

      .container-logo, .logoAguasSaltillo{
        width: 122px;
        height: 54px;
      }
    }
    .headerContainer__navBar-payContainer {
      min-width: 16.5%;

      .headerContainer__navBar-payButton{
        font-size: 18px;
      }
    }
  }

  &.m-header-enter  {
    &.no-is-home{
      opacity: 0;
    }
  }
  &.m-header-enter-active {
    opacity: 1;
    transition: opacity 100ms;
  }
  &.m-header-exit {
    opacity: 1;
  }
  &.m-header-exit-active {
    &.no-is-home{
      opacity: 0;
      transition: opacity 250ms;
    }
  }
`
export default HeaderStyled
