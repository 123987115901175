/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useContext } from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-awesome-styled-grid"
import { CSSTransition } from "react-transition-group"
import { gsap } from "gsap"

import { string } from "prop-types"
import TextLink from "../../elements/links/TextLink"
import SvgHamburger from "../../elements/svgs/SvgHamburger"
import SvgLogoDark from "../../elements/svgs/SvgLogoDark"
import SvgLogoLight from "../../elements/svgs/SvgLogoLight"
import payIcon from "../../../images/pay-icon.png"
import useWindowSize from "../../../hooks/useWindowSize"

import SiteContext from "../../../context/site.context"

import HamburgerMenu from "../hamburgerMenu/menu"

import HeaderStyled from "./Header.style"

const Header = ({ headerType }) => {
  const classNameID = `m-header`
  const [inProp, setInProp] = useState(false)
  const windowSize = useWindowSize()
  const [activeHamburger, setActiveHamburger] = useState(false)
  const { isHome } = useContext(SiteContext)

  useEffect(() => {
    setInProp(true)
  }, [])

  useEffect(() => {
    if (isHome && inProp) {
      const headerModule = document.querySelector(".headerContainer")
      gsap.from(headerModule, { duration: 0.4, opacity: 0, y: -50, delay: 0.3 })
    }
  }, [isHome, inProp])

  const openHamburger = () => {
    setActiveHamburger(true)
  }

  const closeHamburger = () => {
    setActiveHamburger(false)
  }

  return (
    <>
      {activeHamburger && <HamburgerMenu activeHamburger={activeHamburger} handleClose={closeHamburger} />}
      <CSSTransition in={inProp} classNames={classNameID} timeout={inProp ? 100 : 250} unmountOnExit>
        <HeaderStyled className={`${classNameID} ${!isHome ? 'no-is-home' : ''}`}>
          <Container>
            <Row>
              <Col>
                <div className="headerContainer">
                  <div className={`headerContainer__menu ${headerType === "dark" ? "menuDark" : ""}`} onClick={openHamburger}>
                    <SvgHamburger />
                  </div>
                  <nav className={`headerContainer__navBar ${headerType === "dark" ? "headerDark" : ""}`}>
                    {windowSize.innerWidth >= 1024 && (
                      <div className="navBar-buttons">
                        <TextLink to="/acerca-de-nosotros" className={`${classNameID}__link`}>
                          Nosotros
                        </TextLink>
                        <TextLink to="/transparencia" className={`${classNameID}__link`}>
                          Transparencia
                        </TextLink>
                        <TextLink to="/reporte" className={`${classNameID}__link`}>
                          Reportes
                        </TextLink>
                      </div>
                    )}
                    <Link className="container-logo" to="/">
                      {headerType === "dark" ? <SvgLogoDark className="logoAguasSaltillo" /> : <SvgLogoLight className="logoAguasSaltillo" />}
                    </Link>
                    <div className="headerContainer__navBar-payContainer">
                      <TextLink className="headerContainer__navBar-payButton" href="https://oficina.aguasdesaltillo.com/#/main" target="_blank">
                        {windowSize.innerWidth < 768 ? <img className="payIcon" src={payIcon} alt="" /> : "Pago en Línea"}
                      </TextLink>
                    </div>
                  </nav>
                </div>
              </Col>
            </Row>
          </Container>
        </HeaderStyled>
      </CSSTransition>
    </>
  )
}

Header.propTypes = {
  headerType: string,
}

Header.defaultProps = {
  headerType: "",
}

export default Header
