import React from 'react'
import { bool, string } from 'prop-types'

import ModuleStyled from './accordionHeader.style'

const AccordionHeader = ({ title, expanded }) => {
  const classNameID = `m-accordion-header`

  return (
    <ModuleStyled className={`${classNameID} ${expanded ? 'is-expanded' : ''}`}>
      <span className={`${classNameID}__title`}>{title}</span>
    </ModuleStyled>
  )
}

AccordionHeader.propTypes = {
  title: string,
  expanded: bool,
}

AccordionHeader.defaultProps = {
  title: '',
  expanded: false,
}

export default AccordionHeader