import * as React from "react"

const SvgHamburger = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={23} height={17}>
    <path
      stroke="#FFF"
      strokeWidth={2}
      fill="none"
      d="M21 2.986H.999M21 9.2H.999M14.985 14.988H.999"
    />
  </svg>
)

export default SvgHamburger;