import * as React from 'react'
import { string, number, oneOfType } from 'prop-types'

const SvgInstagram = ({ width, height, fill, className }) => (
  <svg width={width} height={height} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 530 530" xmlSpace="preserve">
    <g fill={fill}>
      <path d="M263.9 136.5c70.3-.6 128.2 56.9 128.6 127.6.4 70.2-56.7 127.7-127.4 128.3-70.2.6-128.2-56.9-128.6-127.6-.4-70.2 56.7-127.6 127.4-128.3zm.6 213.2c47.1 0 85.1-38 85.2-85.1s-37.8-85.1-85-85.3-85.6 38.2-85.4 85.4c.1 47.2 38.1 85.1 85.2 85zM403.1 157.7c-17.6 0-31.7-14.1-31.8-31.7-.1-17.7 14.4-32.2 31.9-32.1 17.4.1 31.7 14.3 31.9 31.8.3 17.4-14.3 32-32 32z" />
      <path d="M396.5 517.5h-263c-66.7 0-121-54.3-121-121v-263c0-66.7 54.3-121 121-121h262.9c66.7 0 121 54.3 121 121v262.9c.1 66.8-54.2 121.1-120.9 121.1zm-263-465c-44.7 0-81 36.4-81 81v262.9c0 44.7 36.4 81 81 81h262.9c44.7 0 81-36.4 81-81V133.5c0-44.7-36.4-81-81-81H133.5z" />
    </g>
  </svg>
)

SvgInstagram.propTypes = {
  height: oneOfType([string, number]),
  width: oneOfType([string, number]),
  fill: string,
  className: string,
}

SvgInstagram.defaultProps = {
  height: '1em',
  width: '1em',
  fill: '#000',
  className: '',
}

export default SvgInstagram
