import axios from 'axios'

// eslint-disable-next-line import/prefer-default-export
export const getAllIncidents = (responseHandler, errorHandler) => {
  axios({
    method: 'GET',
    headers: { 'content-type': 'application/json' },
    url: '/api/incidents',
  })
    .then(response => {
      if (responseHandler) responseHandler(response.data)
    })
    .catch(error => {
      if (errorHandler) errorHandler(error)
    })
}
