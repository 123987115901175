import React from 'react'
import { string, number } from 'prop-types'

import ProgressBarStyled from './ProgressBar.style'

const ProgressBar = ({ percent, className }) => {
  const classNameID = `e-progress-bar`

  return (
    <ProgressBarStyled className={`${classNameID} ${className}`} percent={percent}>
      <span className={`${classNameID}__bar`} />
      <span className={`${classNameID}__progress`} />
    </ProgressBarStyled>
  )
}

ProgressBar.propTypes = {
  percent: number,
  className: string,
}

ProgressBar.defaultProps = {
  percent: 0,
  className: '',
}

export default ProgressBar
